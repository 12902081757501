import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import Modal from '@mui/material/Modal';
import LoginForm from '../../forms/LoginForm';
import RegisterForm from '../../forms/RegisterForm';
import ForgotPasswordForm from '../../forms/ForgotPasswordForm';
import LoaderModal from './LoaderModal';
import { useSelector } from 'react-redux';

export default function LoginModal({ open, handleOpen, handleClose, setOpen }) {
	const [status, SetStatus] = useState('login');
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { loading } = useSelector((state) => state.agent);

	return (
		<div>
			<Modal
				open={open}
				sx={{ padding: 10 }}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: matches ? '100%' : 1000,
						bgcolor: 'background.paper',
						outline: 'none',
						boxShadow: '0px 4px 27px 0px #00000012',
						// p: 3,
						borderRadius: 2,
					}}>
					<Grid container>
						{matches ? (
							''
						) : (
							<Grid item xs={4}>
								<img
									src={
										'https://imgak.mmtcdn.com/pwa_v3/pwa_header_assets/loginPersuassionOcean.webp'
									}
									style={{
										height: '100%',
										borderTopLeftRadius: 5,
										borderBottomLeftRadius: 5,
									}}
								/>
							</Grid>
						)}

						{status === 'login' && (
							<Grid item xs={matches ? 12 : 8} style={{ padding: 20 }}>
								<Grid container>
									<Grid item xs={3}></Grid>
									<Grid item xs={6}>
										<Box
											display={'flex'}
											justifyContent={'space-between'}
											alignItems={'center'}
											style={{
												boxShadow: '0 1px 7px 0 rgb(0 0 0 / 30%)',
												backgroundColor: '#ffffff',
												padding: 5,
												borderRadius: 30,
											}}>
											<Box
												style={{
													// backgroundColor: 'rgb(253 121 14) ',
													padding: 12,
													borderRadius: 25,
												}}>
												<h1 style={{ fontSize: 12, color: '' }}>
													PERSONAL ACCOUNT
												</h1>
											</Box>
											<Box
												style={{
													backgroundColor: 'rgb(253 121 14) ',
													padding: 12,
													borderRadius: 25,
													color: 'white',
												}}>
												<h1 style={{ fontSize: 12 }}>FAREEASE AGENTS</h1>
											</Box>
										</Box>
									</Grid>
									<Grid item xs={3}></Grid>
								</Grid>
								<LoginForm
									SetStatus={SetStatus}
									handleClose={handleClose}
									setOpen={setOpen}
								/>
							</Grid>
						)}
						{status === 'register' && (
							<Grid item xs={matches ? 12 : 8} style={{ padding: 20 }}>
								<Grid container>
									<Grid item xs={3}></Grid>
									<Grid item xs={6}>
										<Box
											display={'flex'}
											justifyContent={'space-between'}
											alignItems={'center'}
											style={{
												boxShadow: '0 1px 7px 0 rgb(0 0 0 / 30%)',
												backgroundColor: '#ffffff',
												padding: 5,
												borderRadius: 30,
											}}>
											<Box
												style={{
													// backgroundColor: 'rgb(253 121 14) ',
													padding: 12,
													borderRadius: 25,
												}}>
												<h1 style={{ fontSize: 12, color: '' }}>
													PERSONAL ACCOUNT
												</h1>
											</Box>
											<Box
												style={{
													backgroundColor: 'rgb(253 121 14) ',
													padding: 12,
													borderRadius: 25,
													color: 'white',
												}}>
												<h1 style={{ fontSize: 12 }}>FAREEASE AGENTS</h1>
											</Box>
										</Box>
									</Grid>
									<Grid item xs={3}></Grid>
								</Grid>
								<Box display={'flex'} mt={1}>
									{/* <span
										style={{
											background: 'rgba(235, 144, 9, 1)',
											fontSize: 12,
											color: 'rgba(255, 255, 255, 1)',
											padding: '2px 8px',
											borderRadius: 5,
										}}>
										20% off
									</span>
									<span style={{ fontSize: 12, marginLeft: 5 }}>
										get 20% off for web signup
									</span> */}
								</Box>
								<RegisterForm SetStatus={SetStatus} handleClose={handleClose} />
							</Grid>
						)}
						{status === 'forgotpassword' && (
							<Grid item xs={matches ? 12 : 8} style={{ padding: 20 }}>
								<h1
									style={{
										fontSize: 32,
										//
										fontWeight: '700',
									}}>
									Reset Password
								</h1>
								<Box display={'flex'} mt={1}>
									{/* <span
										style={{
											background: 'rgba(235, 144, 9, 1)',
											fontSize: 12,
											color: 'rgba(255, 255, 255, 1)',
											padding: '2px 8px',
											borderRadius: 5,
										}}>
										20% off
									</span>
									<span style={{ fontSize: 12, marginLeft: 5 }}>
										get 20% off for web signup
									</span> */}
								</Box>
								<ForgotPasswordForm SetStatus={SetStatus} />
							</Grid>
						)}
					</Grid>
				</Box>
			</Modal>
			{/* {loading ? <LoaderModal /> : ''} */}
		</div>
	);
}
