import React from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import {
	Box,
	Container,
	useTheme,
	useMediaQuery,
	Grid,
	Divider,
	Button,
} from '@mui/material';
import StepperHotel from '../components/resuableComponents/StepperHotel';
import FlightSummaryForm from '../forms/FlightSummaryForm';
import HotelSummaryForm from '../forms/HotelSummaryForm';
import { useLocation } from 'react-router-dom';

const ConfirmHotelPage = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const location = useLocation();
	return (
		<>
			<Navbar />
			<MNavbar />
			<Box
				style={{
					background: 'rgb(253 121 14) ',
					padding: matches ? 15 : 25,
				}}>
				<Container>
					<StepperHotel step={2} />
				</Container>
			</Box>
			<Container style={{ marginTop: 30 }}>
				<h1 style={{ fontSize: matches ? 20 : 26, fontWeight: 'bold' }}>
					Confirm your Booking
				</h1>
				<Grid container spacing={4}>
					<Grid item xs={12} lg={8}>
						<HotelSummaryForm />
					</Grid>
					<Grid item xs={12} lg={4}>
						<Box
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
								padding: 15,
							}}>
							<span
								style={{
									fontSize: 18,
									fontWeight: '500',
									color: 'rgba(29, 29, 29, 1)',
								}}>
								Fare Summary
							</span>
							<Divider
								style={{ border: '1.33px solid rgba(203, 203, 203, 1)' }}
							/>
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
										lineHeight: '10px',
									}}>
									Base Fare
								</span>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹
									{Number(
										Math.round(location?.state?.selectedRoom?.Total_Amount)
									)}
								</span>
							</Box>{' '}
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									{/* {'2'} */}
								</p>
								{/* <p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹ 4000
								</p> */}
							</Box>{' '}
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									{/* {'5'} */}
								</p>
							</Box>{' '}
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									{/* {'2'} */}
								</p>
							</Box>{' '}
							<Divider
								style={{ border: '1.33px solid rgba(203, 203, 203, 1)' }}
							/>
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									Taxes
								</span>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹ {location.state.selectedRoom?.Tax_Amount}
								</span>
							</Box>
							{/* <Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									Taxes & Fees
								</p>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹ 4000
								</p>
							</Box>{' '} */}
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									Convience
								</p>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹ 0
								</p>
							</Box>{' '}
							<Divider
								style={{ border: '1.33px solid rgba(203, 203, 203, 1)' }}
							/>
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									Grand Total
								</span>
								<span
									style={{
										fontSize: 16,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹{' '}
									{Number(
										Math.round(location.state.selectedRoom?.Total_Amount)
									)}
								</span>
							</Box>
						</Box>
						<Box
							mt={4}
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
								padding: 20,
							}}>
							<h1>Promo Code</h1>
							<Divider
								style={{ border: '1.33px solid rgba(203, 203, 203, 1)' }}
							/>
							<h2>Have a discount/Promo code to redeem</h2>
							<Box mt={2}>
								<Grid container spacing={2}>
									<Grid item xs={8}>
										<input
											placeholder='Promo Code'
											style={{
												border: '1.33px solid rgba(203, 203, 203, 1)',
												padding: 10,
												width: '100%',
												borderRadius: 5,
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<Button
											style={{
												background: 'rgb(253 121 14) ',
												width: '100%',
												color: 'white',
												padding: 10,
											}}>
											Apply
										</Button>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</>
	);
};

export default ConfirmHotelPage;
