import React, { useEffect } from 'react';
import loc from '../../assets/images/locat.svg';
import calender from '../../assets/images/calender.svg';
import dayjs from 'dayjs';
import flightlogo from '../../assets/images/flightlogo.svg';
import arrivalflight from '../../assets/images/arrialflight.svg';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import flight from '../../assets/images/flight.jpg';
import hotel from '../../assets/images/hotel.jpg';
import holiday from '../../assets/images/holiday.jpg';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import search from '../../assets/images/search.svg';
import Guest from '../../assets/images/Guest.svg';
import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {
	Grid,
	TextField,
	Box,
	useMediaQuery,
	useTheme,
	Container,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'antd';
import moment from 'moment';
import Navbar from '../resuableComponents/Navbar/Navbar';
// import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
	cityDispatch,
	showBannerDispatch,
	// showSeoDispatch,
} from '../../reducers/HomeReducer';
import {
	setSelectedArrival,
	setSelectedDepart,
	setTrip,
} from '../../reducers/UiReducer';
import TravelTabs from './TravelTabs';
import SpecialFare from './SpecialFare';
// import DynamicPage from './DynamicPage';
const dateFormat = 'DD-MM-YYYY';

const NewSlider = () => {
	const { city, banner, tourpackage, seo } = useSelector((state) => state.home);
	const { depart, arrival, trip } = useSelector((state) => state.ui);
	const { agentInfo } = useSelector((state) => state.agent);
	const date = new Date();
	const [num, setNum] = useState(1);
	const [adult, setAdult] = useState(1);
	const [child, setChild] = useState(0);
	const [infant, setInfant] = useState(0);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [departDate, setDepartDate] = useState('');
	const [departDateReturn, setDepartDateReturn] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	let dateFor = date.toLocaleString().split(',')[0].split('/');
	const newDate = `${dateFor[1].length > 1 ? '' : 0}${dateFor[1]}-${
		dateFor[0].length > 1 ? '' : 0
	}${dateFor[0]}-${dateFor[2]}`;
	useEffect(() => {
		let cityData = {
			keywords: 'b',
			limits: 8,
		};
		dispatch(cityDispatch(cityData));
		dispatch(showBannerDispatch());
		// dispatch(showSeoDispatch());
	}, []);
	let filter = [];
	if (banner?.result?.length > 0) {
		banner?.result?.map((i) => {
			if (i.inventory === 'Flight') {
				filter.push({
					...i,
				});
			}
		});
	}
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handlePrev = () => {
		if (num > 1) {
			setNum(num - 1);
		}
	};
	const handleNext = () => {
		if (num > 0 && num < filter.length) {
			setNum(num + 1);
		}
	};
	const handleChange = (event) => {
		let value = event.target.value.toLowerCase();
		let cityData = {
			keywords: value.trim().replace(/\s+/g, ' '),
			limits: 10,
		};
		dispatch(cityDispatch(cityData));
	};
	const handleTrip = (val) => {
		dispatch(setTrip(val));
	};

	function disabledDate(current) {
		return moment().add(-1, 'days') >= current;
	}
	const onChange = (date, dateString) => {
		setDepartDate(dateString);
	};
	const onChangeReturn = (date, dateString) => {
		setDepartDateReturn(dateString);
	};
	const handleTBO = () => {
		if (trip === 'oneway') {
			let formData = {
				AdultCount: adult,
				ChildCount: child,
				InfantCount: infant,
				DirectFlight: false,
				OneStopFlight: false,
				JourneyType: 1,
				PreferredAirlines: null,
				Segments: [
					{
						Origin: depart.airport_code,
						Destination: arrival.airport_code,
						FlightCabinClass: 1,
						PreferredDepartureTime: departDate
							? `${departDate.split('-')[2]}-${departDate.split('-')[1]}-${
									departDate.split('-')[0]
							  }`
							: `${newDate.split('-')[2]}-${newDate.split('-')[1]}-${
									newDate.split('-')[0]
							  }`,
					},
				],
				Sources: 'WEB',
				agent_id: agentInfo?.status === 200 ? agentInfo?.result?.result?.id : 0,
			};
			navigate('/flightresult', { state: formData });
		} else {
			if (depart.country_code === 'IN' && arrival.country_code === 'IN') {
				let formData = {
					AdultCount: adult,
					ChildCount: child,
					InfantCount: infant,
					DirectFlight: false,
					OneStopFlight: false,
					JourneyType: 2,
					PreferredAirlines: null,
					Segments: [
						{
							Origin: depart.airport_code,
							Destination: arrival.airport_code,
							FlightCabinClass: 1,
							PreferredDepartureTime: departDate
								? `${departDate.split('-')[2]}-${departDate.split('-')[1]}-${
										departDate.split('-')[0]
								  }`
								: `${newDate.split('-')[2]}-${newDate.split('-')[1]}-${
										newDate.split('-')[0]
								  }`,
						},
						{
							Origin: arrival.airport_code,
							Destination: depart.airport_code,
							FlightCabinClass: 1,
							PreferredDepartureTime: departDateReturn
								? `${departDateReturn.split('-')[2]}-${
										departDateReturn.split('-')[1]
								  }-${departDateReturn.split('-')[0]}`
								: `${newDate.split('-')[2]}-${newDate.split('-')[1]}-${
										newDate.split('-')[0]
								  }`,
						},
					],
					Sources: 'WEB',
					agent_id:
						agentInfo?.status === 200 ? agentInfo?.result?.result?.id : 0,
				};
				navigate('/flightroundtripresult', { state: formData });
			} else {
				let formData = {
					AdultCount: adult,
					ChildCount: child,
					InfantCount: infant,
					DirectFlight: false,
					OneStopFlight: false,
					JourneyType: 2,
					PreferredAirlines: null,
					Segments: [
						{
							Origin: depart.airport_code,
							Destination: arrival.airport_code,
							FlightCabinClass: 1,
							PreferredDepartureTime: departDate
								? `${departDate.split('-')[2]}-${departDate.split('-')[1]}-${
										departDate.split('-')[0]
								  }`
								: `${newDate.split('-')[2]}-${newDate.split('-')[1]}-${
										newDate.split('-')[0]
								  }`,
						},
						{
							Origin: arrival.airport_code,
							Destination: depart.airport_code,
							FlightCabinClass: 1,
							PreferredDepartureTime: departDateReturn
								? `${departDateReturn.split('-')[2]}-${
										departDateReturn.split('-')[1]
								  }-${departDateReturn.split('-')[0]}`
								: `${newDate.split('-')[2]}-${newDate.split('-')[1]}-${
										newDate.split('-')[0]
								  }`,
						},
					],
					Sources: 'WEB',
					agent_id:
						agentInfo?.status === 200 ? agentInfo?.result?.result?.id : 0,
				};
				navigate('/flightroundInternationaltripresult', { state: formData });
			}
		}
	};
	return (
		<>
			<Box style={{ paddingLeft: 80, paddingRight: 80, paddingTop: 50 }}>
				<TravelTabs />
				<div
					style={{
						backgroundColor: 'rgba(255, 255, 255, 0.12)',
						borderRadius: 5,
						padding: 20,
						paddingTop: 60,
					}}>
					<Box
						display={'flex'}
						alignItems={'center'}
						style={{ marginBottom: 5 }}>
						<Box
							onClick={() => handleTrip('oneway')}
							style={{
								backgroundColor:
									trip === 'oneway' ? 'rgba(237, 160, 13, 1)' : '',
								padding: '5px 20px',
								color: 'white',
								borderRadius: 25,
								cursor: 'pointer',
								fontSize: 15,
							}}>
							ONE-WAY
						</Box>
						<Box
							onClick={() => handleTrip('roundtrip')}
							ml={2}
							style={{
								padding: '5px 20px',
								color: 'white',
								borderRadius: 25,
								backgroundColor:
									trip === 'roundtrip' ? 'rgba(237, 160, 13, 1)' : '',
								cursor: 'pointer',
								fontSize: 15,
							}}>
							ROUND-TRIP
						</Box>
					</Box>
					<Grid container alignItems={'center'} spacing={2}>
						<Grid item xs={12} lg={trip === 'roundtrip' ? 2.5 : 3.5}>
							<Box display={'flex'}>
								<img
									src={loc}
									style={{
										height: 25,
										width: 25,
										objectFit: 'contain',
									}}
								/>
								<Box style={{ width: '100%' }}>
									<span
										style={{
											color: 'white',
											marginLeft: 10,
											fontSize: 20,
										}}>
										From
									</span>
									<Autocomplete
										required
										id='country-select-demo'
										sx={{
											width: matches
												? '100%'
												: trip === 'oneway'
												? '100%'
												: '100%',
											marginLeft: 2,
											marginTop: 1,
											'& .MuiFormLabel-root': { color: 'white' },
											'& .MuiInputBase-input': {
												color: 'rgba(207, 201, 201, 1)',
												fontSize: 20,
											},
											'& .MuiSvgIcon-root': { color: 'white' },
										}}
										options={city?.result?.length > 0 ? city.result : []}
										autoHighlight
										ListboxProps={{ className: 'myCustomList' }}
										filterOptions={(options, state) => {
											const inputValue = state.inputValue.trim().toLowerCase();
											return options.filter(
												(option) =>
													option.city_name.toLowerCase().includes(inputValue) ||
													option.airport_code
														.toLowerCase()
														.includes(inputValue) ||
													option.airport_name.toLowerCase().includes(inputValue)
											);
										}}
										value={depart}
										getOptionLabel={(option) =>
											`${option.city_name} (${option.airport_code})`.trim()
										}
										renderOption={(props, option) => (
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
												}}
												// component='li'
												{...props}>
												<Box>
													<Box display='flex'>
														<img
															src={flightlogo}
															style={{ width: 20, height: 20 }}
														/>
														<p style={{ fontSize: 13, marginLeft: 5 }}>
															{option.city_name} - {option.airport_code}
														</p>
													</Box>
													<p
														style={{
															fontSize: 13,
															marginLeft: 25,
															fontFamily: 'Outfit',
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: trip === 'oneway' ? '200px' : '120px',
															whiteSpace: 'nowrap',
														}}>
														{option.airport_name} airport
													</p>
												</Box>
												<img
													loading='lazy'
													width='25'
													height='25'
													src={`https://flagcdn.com/w20/${option.country_code.toLowerCase()}.png`}
													srcSet={`https://flagcdn.com/w40/${option.country_code.toLowerCase()}.png 2x`}
													alt=''
												/>
											</Box>
										)}
										onInputChange={(event, newInputValue) => {
											if (newInputValue.trim() === '') {
												dispatch(setSelectedDepart(null));
											}
										}}
										onChange={(event, value) => {
											dispatch(setSelectedDepart(value));
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												id='standard-basic'
												variant='standard'
												onChange={handleChange}
												style={{ fontSize: 12 }}
												inputProps={{
													...params.inputProps,
													autoComplete: 'new-password', // Disable autocomplete and autofill
												}}
											/>
										)}
									/>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} lg={trip === 'roundtrip' ? 2.5 : 3.5}>
							<Box display={'flex'}>
								<img
									src={loc}
									style={{
										height: 25,
										width: 25,
										objectFit: 'contain',
									}}
								/>
								<Box style={{ width: '100%' }}>
									<span
										style={{
											color: 'white',
											marginLeft: 10,
											fontSize: 20,
										}}>
										To
									</span>
									<Autocomplete
										id='country-select-demo'
										sx={{
											width: matches
												? '100%'
												: trip === 'oneway'
												? '100%'
												: '100%',
											marginLeft: 2,
											marginTop: 1,
											'& .MuiFormLabel-root': { color: 'white' },
											'& .MuiInputBase-input': {
												color: 'rgba(207, 201, 201, 1)',
												fontSize: 20,
											},
											'& .MuiSvgIcon-root': { color: 'white' },
										}}
										options={city?.result?.length > 0 ? city.result : []}
										autoHighlight
										ListboxProps={{ className: 'myCustomList' }}
										value={arrival}
										filterOptions={(options, state) => {
											const inputValue = state.inputValue.trim().toLowerCase();
											return options.filter(
												(option) =>
													option.city_name.toLowerCase().includes(inputValue) ||
													option.airport_code
														.toLowerCase()
														.includes(inputValue) ||
													option.airport_name.toLowerCase().includes(inputValue)
											);
										}}
										getOptionLabel={(option) =>
											`${option.city_name} (${option.airport_code})`.trim()
										}
										renderOption={(props, option) => (
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
												}}
												component='li'
												{...props}>
												<Box>
													<Box display='flex'>
														<img
															src={flightlogo}
															style={{ width: 20, height: 20 }}
														/>
														<p style={{ fontSize: 13, marginLeft: 5 }}>
															{option.city_name} - {option.airport_code}
														</p>
													</Box>
													<p
														style={{
															fontSize: 13,
															marginLeft: 25,
															fontFamily: 'Outfit',
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: trip === 'oneway' ? '200px' : '120px',
															whiteSpace: 'nowrap',
														}}>
														{option.airport_name} airport
													</p>
												</Box>
												<img
													loading='lazy'
													width='25'
													height='25'
													src={`https://flagcdn.com/w20/${option.country_code.toLowerCase()}.png`}
													srcSet={`https://flagcdn.com/w40/${option.country_code.toLowerCase()}.png 2x`}
													alt=''
												/>
											</Box>
										)}
										onInputChange={(event, newInputValue) => {
											if (newInputValue.trim() === '') {
												dispatch(setSelectedArrival(null));
											}
										}}
										onChange={(event, value) => {
											dispatch(setSelectedArrival(value));
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												id='standard-basic'
												variant='standard'
												onChange={handleChange}
												style={{ fontSize: 12 }}
												inputProps={{
													...params.inputProps,
													autoComplete: 'new-password', // Disable autocomplete and autofill
												}}
											/>
										)}
									/>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} lg={2}>
							<Box display={'flex'} style={{ width: '100%' }}>
								<img
									src={calender}
									style={{
										height: 25,
										width: 25,
										objectFit: 'contain',
										marginTop: 5,
									}}
								/>
								<Box style={{ width: '100%' }}>
									<span
										style={{
											color: 'white',
											marginLeft: 10,
											fontSize: 20,
										}}>
										Departure
									</span>
									<DatePicker
										disabledDate={disabledDate}
										onChange={onChange}
										placeholder=''
										defaultValue={dayjs(newDate, dateFormat)}
										format={dateFormat}
										style={{
											width: '100%',
											padding: 8,
											border: 'none',
											borderBottom: '1px solid',
											borderRadius: '1px',
											boxShadow: 'none',
											background: 'transparent',
											fontSize: 20,
										}}
									/>
								</Box>
							</Box>
						</Grid>
						{trip === 'roundtrip' && (
							<Grid item xs={2}>
								<Box display={'flex'} style={{ width: '100%' }}>
									<img
										src={calender}
										style={{
											height: 25,
											width: 25,
											objectFit: 'contain',
											marginTop: 5,
										}}
									/>
									<Box>
										<span
											style={{
												color: 'white',
												marginLeft: 10,
												fontSize: 20,
											}}>
											Return
										</span>
										<DatePicker
											disabledDate={disabledDate}
											onChange={onChangeReturn}
											placeholder=''
											format={dateFormat}
											style={{
												width: '100%',
												padding: 8,
												border: 'none',
												borderBottom: '1px solid',
												borderRadius: '1px',
												boxShadow: 'none',
												background: 'transparent',
											}}
										/>
									</Box>
								</Box>
							</Grid>
						)}
						<Grid item xs={12} lg={2}>
							<Box display={'flex'}>
								<img
									src={Guest}
									style={{
										height: 25,
										width: 25,
										objectFit: 'contain',
										marginTop: 5,
									}}
								/>
								<Box>
									<span
										style={{
											color: 'white',
											marginLeft: 10,
											fontSize: 14,
										}}>
										Guest
									</span>
									<Button
										style={{
											backgroud: 'transparent',
											color: 'white',
											textTransform: 'capitalize',
										}}
										aria-describedby={id}
										variant='text'
										onClick={handleClick}>
										{adult} Adult {child} Child
									</Button>
									<Popover
										id={id}
										open={open}
										style={{ marginTop: 5, padding: 10 }}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left',
										}}>
										<Box
											display={'flex'}
											justifyContent={'space-between'}
											alignItems={'center'}>
											<Typography sx={{ p: 2, fontSize: 12 }}>
												ADULTS (12y +)<br></br>
												<span style={{ fontSize: 10 }}>
													on the day of travel
												</span>
											</Typography>
											<Box display={'flex'} alignItems={'center'}>
												<Box
													onClick={() => setAdult(adult - 1)}
													style={{
														border: '1px solid lightskyblue',
														padding: '4px 20px',
														marginLeft: 10,
														borderRadius: 5,
														cursor: 'pointer',
													}}>
													-
												</Box>
												<Box
													style={{
														backgroud: 'green',
														marginLeft: 10,
														marginRight: 10,
													}}>
													{adult}
												</Box>
												<Box
													onClick={() => setAdult(adult + 1)}
													style={{
														border: '1px solid lightskyblue',
														padding: '4px 20px',
														marginRight: 10,
														marginLeft: 5,
														borderRadius: 5,
														cursor: 'pointer',
													}}>
													+
												</Box>
											</Box>
										</Box>
										<Box
											display={'flex'}
											justifyContent={'space-between'}
											alignItems={'center'}>
											<Typography sx={{ p: 2, fontSize: 12 }}>
												CHILDS (2y-12y)<br></br>
												<span style={{ fontSize: 10 }}>
													on the day of travel
												</span>
											</Typography>
											<Box display={'flex'} alignItems={'center'}>
												<Box
													onClick={() => setChild(child - 1)}
													style={{
														border: '1px solid lightskyblue',
														padding: '4px 20px',
														marginLeft: 10,
														borderRadius: 5,
														cursor: 'pointer',
													}}>
													-
												</Box>
												<Box
													style={{
														backgroud: 'green',
														marginLeft: 10,
														marginRight: 10,
													}}>
													{child}
												</Box>
												<Box
													onClick={() => setChild(child + 1)}
													style={{
														border: '1px solid lightskyblue',
														padding: '4px 20px',
														marginRight: 10,
														marginLeft: 5,
														borderRadius: 5,
														cursor: 'pointer',
													}}>
													+
												</Box>
											</Box>
										</Box>
										<Box
											display={'flex'}
											justifyContent={'space-between'}
											alignItems={'center'}>
											<Typography sx={{ p: 2, fontSize: 12 }}>
												INFANTS (below 2y)<br></br>
												<span style={{ fontSize: 10 }}>
													on the day of travel
												</span>
											</Typography>
											<Box display={'flex'} alignItems={'center'}>
												<Box
													onClick={() => setInfant(infant - 1)}
													style={{
														border: '1px solid lightskyblue',
														padding: '4px 20px',
														marginLeft: 10,
														borderRadius: 5,
														cursor: 'pointer',
													}}>
													-
												</Box>
												<Box
													style={{
														backgroud: 'green',
														marginLeft: 10,
														marginRight: 10,
													}}>
													{infant}
												</Box>
												<Box
													onClick={() => setInfant(infant + 1)}
													style={{
														border: '1px solid lightskyblue',
														padding: '4px 20px',
														marginRight: 10,
														marginLeft: 5,
														borderRadius: 5,
														cursor: 'pointer',
													}}>
													+
												</Box>
											</Box>
										</Box>
										<FormControl style={{ padding: 10 }}>
											<FormLabel
												id='demo-radio-buttons-group-label'
												style={{ fontSize: 14 }}>
												CHOOSE TRAVEL CLASS
											</FormLabel>
											<RadioGroup
												aria-labelledby='demo-radio-buttons-group-label'
												defaultValue='Economy/Premium Economy'
												name='radio-buttons-group'>
												<FormControlLabel
													sx={{
														'& .MuiTypography-root ': {
															fontSize: 12,
														},
													}}
													value='Economy/Premium Economy'
													control={<Radio size='small' />}
													label='Economy/Premium Economy'
												/>
												<FormControlLabel
													sx={{
														'& .MuiTypography-root ': {
															fontSize: 12,
														},
													}}
													value='Premium Economy'
													control={<Radio size='small' />}
													label='Premium Economy'
												/>
												<FormControlLabel
													sx={{
														'& .MuiTypography-root ': {
															fontSize: 12,
														},
													}}
													value='Business'
													control={<Radio size='small' />}
													label='Business'
												/>
											</RadioGroup>
										</FormControl>
									</Popover>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} lg={1}>
							<Box
								onClick={handleTBO}
								style={{
									backgroundColor: 'rgb(253, 121, 14)',
									textAlign: 'center',
									padding: matches ? '20px' : '30px',
									borderRadius: '5px',
									cursor: 'pointer',
								}}
								textAlign={'center'}>
								<img
									src={search}
									style={{
										height: 25,
										width: 25,
										display: 'inline',
										objectFit: 'contain',
									}}
								/>
							</Box>
						</Grid>
					</Grid>
					<Box>
						<SpecialFare />
					</Box>
				</div>
			</Box>
		</>
	);
};

export default NewSlider;
