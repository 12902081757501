import { Box, Divider } from '@mui/material';
import React from 'react';
// import './Card.css';

const Card = () => {
	return (
		<div className='card'>
			<div className='card-image'>
				<span className='deal-badge'>Deal of the day</span>
				<img
					src='https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/cities/1328/Q.jpg' // Replace with your image URL
					alt='Beach'
				/>
				{/* <div className='more-options'>1 More Option Available</div> */}
			</div>
			<div className='card-content'>
				<h3>Your Dream Goa Getaway - Best Price ...</h3>
				<p className='subtitle'>3N Goa</p>
				<Divider style={{ border: '1px solid lightgrey', marginBottom: 5 }} />
				<Box display={'flex'} justifyContent={'space-between'}>
					<Box>
						<li style={{ fontSize: 13 }}>Round Trip Flights</li>
					</Box>
					<Box>
						<li style={{ fontSize: 13 }}>Round Trip Flights</li>
					</Box>
				</Box>
				<Box display={'flex'} justifyContent={'space-between'}>
					<Box>
						<li style={{ fontSize: 13 }}>Round Trip Flights</li>
					</Box>
					<Box>
						<li style={{ fontSize: 13 }}>Round Trip Flights</li>
					</Box>
				</Box>

				<p className='price-note' style={{ marginTop: 10 }}>
					This price is lower than the average price in December
				</p>
				<div className='price'>
					<div>
						<p className='price-per-person'>
							₹26,659 <span>/Person</span>
						</p>
					</div>
					<p className='total-price'>Total Price ₹53,318</p>
				</div>
			</div>
		</div>
	);
};

export default Card;
