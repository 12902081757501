import { axiosInstance } from '../Axios';
import {
	setFormData,
	setModalEasebuzz,
	setModalPay,
	storeData,
} from './UiReducer';
const { createSlice } = require('@reduxjs/toolkit');

const TBOReducer = createSlice({
	name: 'tbo',
	initialState: {
		success: false,
		error: null,
		loading: false,
		loadingReturn: false,
		loadingRule: false,
		tbo: null,
		tbo2: null,
		fareRule: null,
		farequote: null,
		farequoteReturn: null,
		bookGDS: null,
		bookingDetail: sessionStorage.getItem('bookingDetail')
			? JSON.parse(sessionStorage.getItem('bookingDetail'))
			: null,
		orderId: null,
		addBooking: null,
		debitPayment: null,
		markupFlight: null,
		debitRoundtrip: null,
		bookingDetailONWARD: sessionStorage.getItem('bookingDetailONWARD')
			? JSON.parse(sessionStorage.getItem('bookingDetailONWARD'))
			: null,
		bookingDetailRETURN: sessionStorage.getItem('bookingDetailRETURN')
			? JSON.parse(sessionStorage.getItem('bookingDetailRETURN'))
			: null,
		ticketGDSONWARD: sessionStorage.getItem('ticketGDSONWARD')
			? JSON.parse(sessionStorage.getItem('ticketGDSONWARD'))
			: null,
		ticketGDSRETURN: sessionStorage.getItem('ticketGDSRETURN')
			? JSON.parse(sessionStorage.getItem('ticketGDSRETURN'))
			: null,
	},
	reducers: {
		addSearchRequest(state, action) {
			state.loading = true;
		},
		addsearchSuccess(state, action) {
			state.loading = false;
			state.orderId = action.payload;
			state.error = null;
		},
		addSearchFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		tboSearchRequest(state, action) {
			state.loading = true;
		},
		tboSearchSuccess(state, action) {
			state.loading = false;
			state.tbo = action.payload;
			storeData('tbo', action.payload);
			state.error = null;
		},
		tboSearchFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		tboSearch2Request(state, action) {
			// state.loading = true;
		},
		tboSearch2Success(state, action) {
			// state.loading = false;
			state.tbo2 = action.payload;
			storeData('tbo2', action.payload);
			state.error = null;
		},
		tboSearch2Fail(state, action) {
			// state.loading = false;
			state.error = action.payload;
		},
		fareRuleRequest(state, action) {
			state.loadingRule = true;
		},
		fareRuleSuccess(state, action) {
			state.loadingRule = false;
			state.fareRule = action.payload;
			state.error = null;
		},
		fareRuleFail(state, action) {
			state.loadingRule = false;
			state.error = action.payload;
		},
		farequoteRequest(state, action) {
			state.loading = true;
		},
		farequoteSuccess(state, action) {
			state.loading = false;
			state.farequote = action.payload;
			state.error = null;
		},
		farequoteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		ssrRequest(state, action) {
			state.loading = true;
		},
		ssrSuccess(state, action) {
			state.loading = false;
			state.ssr = action.payload;
			state.error = null;
		},
		ssrFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bookGDSRequest(state, action) {
			state.loading = true;
		},
		bookGDSSuccess(state, action) {
			state.loading = false;
			state.bookGDS = action.payload;
			state.error = null;
		},
		bookGDSFail(state, action) {
			state.loading = false;
		},
		bookLCCRequest(state, action) {
			state.loading = true;
		},
		bookLCCSuccess(state, action) {
			state.loading = false;
			state.bookingDetail = action.payload;
			storeData('bookingDetail', action.payload);
			state.error = null;
		},
		bookLCCFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addBookingRequest(state, action) {
			state.loading = true;
		},
		addBookingSuccess(state, action) {
			state.loading = false;
			state.addBooking = action.payload;
			state.error = null;
		},
		addBookingFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		debitRequest(state, action) {
			state.loading = true;
		},
		debitSuccess(state, action) {
			state.loading = false;
			state.debitPayment = action.payload;
			state.error = null;
		},
		debitFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		ticketGDSRequest(state, action) {
			state.loading = true;
		},
		ticketGDSSuccess(state, action) {
			state.loading = false;
			state.bookingDetail = action.payload;
			storeData('bookingDetail', action.payload);
			state.error = null;
		},
		ticketGDSFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		markupRequest(state, action) {
			state.loading = true;
		},
		markupSuccess(state, action) {
			state.loading = false;
			state.markupFlight = action.payload;
			state.error = null;
		},
		markupFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		farequoteReturnRequest(state, action) {
			state.loadingReturn = true;
		},
		farequoteReturnSuccess(state, action) {
			state.loadingReturn = false;
			state.farequoteReturn = action.payload;
			state.error = null;
		},
		farequoteReturnFail(state, action) {
			state.loadingReturn = false;
			state.error = action.payload;
		},
		debitRoundtripRequest(state, action) {
			state.loading = true;
		},
		debitRoundtripSuccess(state, action) {
			state.loading = false;
			state.debitRoundtrip = action.payload;
			state.error = null;
		},
		debitRoundtripFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bookLCCONWARDRequest(state, action) {
			state.loading = true;
		},
		bookLCCONWARDSuccess(state, action) {
			state.loading = false;
			state.bookingDetailONWARD = action.payload;
			storeData('bookingDetailONWARD', action.payload);
			state.error = null;
		},
		bookLCCONWARDFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bookLCCRETURNRequest(state, action) {
			state.loadingReturn = true;
		},
		bookLCCRETURNSuccess(state, action) {
			state.loadingReturn = false;
			state.bookingDetailRETURN = action.payload;
			storeData('bookingDetailRETURN', action.payload);
			state.error = null;
		},
		bookLCCRETURNFail(state, action) {
			state.loadingReturn = false;
			state.error = action.payload;
		},
		bookGDSONWARDRequest(state, action) {
			state.loading = true;
		},
		bookGDSONWARDSuccess(state, action) {
			state.loading = false;
			state.bookGDSONWARD = action.payload;
			storeData('bookGDSONWARD', action.payload);
			state.error = null;
		},
		bookGDSONWARDFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bookGDSRETURNRequest(state, action) {
			state.loadingReturn = true;
		},
		bookGDSRETURNSuccess(state, action) {
			state.loadingReturn = false;
			state.bookGDSRETURN = action.payload;
			storeData('bookGDSRETURN', action.payload);
			state.error = null;
		},
		bookGDSRETURNFail(state, action) {
			state.loadingReturn = false;
			state.error = action.payload;
		},
		ticketGDSONWARDRequest(state, action) {
			state.loading = true;
		},
		ticketGDSONWARDSuccess(state, action) {
			state.loading = false;
			state.ticketGDSONWARD = action.payload;
			storeData('ticketGDSONWARD', action.payload);
			state.error = null;
		},
		ticketGDSONWARDFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		ticketGDSRETURNRequest(state, action) {
			state.loadingReturn = true;
		},
		ticketGDSRETURNSuccess(state, action) {
			state.loadingReturn = false;
			state.ticketGDSRETURN = action.payload;
			storeData('ticketGDSRETURN', action.payload);
			state.error = null;
		},
		ticketGDSRETURNFail(state, action) {
			state.loadingReturn = false;
			state.error = action.payload;
		},
	},
});

const { actions } = TBOReducer;
const config = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': 'NjI1NDI3MjBfareease==',
		// 'Api-Key': 'NjA4ZGFkYWQ2MjY4MA==',
	},
};
export const {
	addSearchRequest,
	addsearchSuccess,
	addSearchFail,
	tboSearchRequest,
	tboSearchSuccess,
	tboSearchFail,
	tboSearch2Request,
	tboSearch2Success,
	tboSearch2Fail,
	fareRuleRequest,
	fareRuleSuccess,
	fareRuleFail,
	farequoteRequest,
	farequoteSuccess,
	farequoteFail,
	ssrRequest,
	ssrSuccess,
	ssrFail,
	bookGDSRequest,
	bookGDSSuccess,
	bookGDSFail,
	bookLCCRequest,
	bookLCCSuccess,
	bookLCCFail,
	addBookingRequest,
	addBookingSuccess,
	addBookingFail,
	debitRequest,
	debitSuccess,
	debitFail,
	ticketGDSRequest,
	ticketGDSSuccess,
	ticketGDSFail,
	markupRequest,
	markupSuccess,
	markupFail,
	farequoteReturnRequest,
	farequoteReturnSuccess,
	farequoteReturnFail,
	debitRoundtripRequest,
	debitRoundtripSuccess,
	debitRoundtripFail,
	bookLCCONWARDRequest,
	bookLCCONWARDSuccess,
	bookLCCONWARDFail,
	bookLCCRETURNRequest,
	bookLCCRETURNSuccess,
	bookLCCRETURNFail,
	bookGDSONWARDRequest,
	bookGDSONWARDSuccess,
	bookGDSONWARDFail,
	bookGDSRETURNRequest,
	bookGDSRETURNSuccess,
	bookGDSRETURNFail,
	ticketGDSONWARDRequest,
	ticketGDSONWARDSuccess,
	ticketGDSONWARDFail,
	ticketGDSRETURNRequest,
	ticketGDSRETURNSuccess,
	ticketGDSRETURNFail,
} = actions;

export const addSearchFlightDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(addSearchRequest());
		const { data } = await axiosInstance.post(
			'/flight/addsearchflight',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(addsearchSuccess(data));
		if (data.status === 200) {
			dispatch(tboFlightSearchDispatch(bodyData));
			dispatch(tboFlightSearch2Dispatch(bodyData));
			// dispatch(setFormData(bodyData));
		}
	} catch (error) {
		dispatch(
			addSearchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const tboFlightSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(tboSearchRequest());
		const { data } = await axiosInstance.post(
			'/flytbo/flightsearch',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(tboSearchSuccess(data));
	} catch (error) {
		dispatch(
			tboSearchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const tboFlightSearch2Dispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(tboSearch2Request());
		const { data } = await axiosInstance.post(
			'/flytbo/flightsearch',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(tboSearch2Success(data));
	} catch (error) {
		dispatch(
			tboSearch2Fail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const TBOFlightFareRuleDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(fareRuleRequest());
		// dispatch(fareRuleSuccess(null));
		const { data } = await axiosInstance.post(
			'/FlyTbo/flightfarerule',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(fareRuleSuccess(data));
	} catch (error) {
		dispatch(
			fareRuleFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const TBOFareQuoteDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			dispatch(farequoteRequest());
			const { data } = await axiosInstance.post(
				'/FlyTbo/flightfarequote',
				bodyData,
				config
			);
			dispatch(farequoteSuccess(data));
			console.log('data', data);
			if (data.status === 200) {
			} else {
				alert('Fare Quote failed from the Supplier end. Please try again.');
				navigate('/flight');
			}
		} catch (error) {
			dispatch(
				farequoteFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const TBOFareQuoteReturnDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			dispatch(farequoteReturnRequest());
			const { data } = await axiosInstance.post(
				'/FlyTbo/flightfarequote',
				bodyData,
				config
			);
			dispatch(farequoteReturnSuccess(data));
			console.log('data', data);
			if (data.status === 200) {
			} else {
				alert('Fare Quote failed from the Supplier end. Please try again.');
				navigate('/flight');
			}
		} catch (error) {
			dispatch(
				farequoteReturnFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const TBOSSRDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(ssrRequest());
		const { data } = await axiosInstance.post(
			'FlyTbo/specialservicerequest',
			bodyData,
			config
		);
		dispatch(ssrSuccess());
		console.log('data', data);
	} catch (error) {
		dispatch(
			ssrFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const TBOBookGDSDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(bookGDSRequest());
			const { data } = await axiosInstance.post(
				'FlyTbo/flightbookgds',
				bodyData,
				config
			);
			dispatch(bookGDSSuccess(data));
			console.log('Data', data);
			if (data.status === 200) {
				let formData = {
					TraceId: bodyData.TraceId,
					PNR: data.result.Response.Response.PNR,
					BookingId: data.result.Response.Response.BookingId,
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				console.log('TicketGDS', formData);
				dispatch(FlightTBOTicketGDSDispatch(formData, setLoading, navigate));
			} else {
				setLoading(false);
				dispatch(setModalPay(false));
				alert('Your session is expired.Please try again.');
				navigate('/flight');
			}
		} catch (error) {
			dispatch(
				bookGDSFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightTBOTicketGDSDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(ticketGDSRequest());
			console.log('ticketgdsprocess');
			const { data } = await axiosInstance.post(
				'FlyTbo/flightticketgds',
				bodyData,
				config
			);
			dispatch(ticketGDSSuccess(data));
			if (data.status === 200) {
				let markupData = {
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				dispatch(flightMarupAmount(markupData));
				setLoading(false);
				dispatch(setModalPay(false));
				navigate('/flightbook', { state: markupData });
			} else {
				setLoading(false);
				dispatch(setModalPay(false));
				alert('Booking Failed From Supplier End.Please try again.');
				navigate('/flight');
			}
		} catch (error) {
			dispatch(
				ticketGDSFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const TBOBookLCCDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(bookLCCRequest());
			const { data } = await axiosInstance.post(
				'/FlyTbo/flightbooklcc',
				bodyData,
				config
			);
			console.log('Data', data);
			dispatch(bookLCCSuccess(data));
			if (data.status === 200) {
				setLoading(false);
				dispatch(setModalPay(false));
				let markupData = {
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				dispatch(flightMarupAmount(markupData));
				navigate('/flightbook', { state: markupData });
			} else {
				setLoading(false);
				dispatch(setModalPay(false));
				alert('Booking Failed From Supplier End.Please try again.');
				navigate('/flight');
			}
		} catch (error) {
			dispatch(
				bookLCCFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightAddBookingDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			dispatch(addBookingRequest());
			const { data } = await axiosInstance.post(
				'/flight/addbookingflight',
				bodyData,
				config
			);
			dispatch(addBookingSuccess(data));
			console.log('Data', data);
			if (data.status === 200) {
				if (bodyData.paymentMode === 'OFFLINE') {
					dispatch(setModalPay(true));
				} else {
					dispatch(setModalEasebuzz(true));
				}
			} else {
				alert('Your session is expired.Please try again.');
				navigate('/flight');
			}
		} catch (error) {
			alert('Your session is expired.Please try again.');
			navigate('/flight');
			dispatch(
				addBookingFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const flightDebitAmount =
	(bodyData, formData, setLoading, navigate, location) => async (dispatch) => {
		try {
			dispatch(debitRequest());

			const { data } = await axiosInstance.post(
				'flight/debitflightpayment',
				bodyData,
				config
			);
			console.log(data);
			dispatch(debitSuccess(data));
			if (data.status === 200) {
				if (location.state.item.IsLCC) {
					dispatch(TBOBookLCCDispatch(formData, setLoading, navigate));
				} else {
					dispatch(TBOBookGDSDispatch(formData, setLoading, navigate));
				}
			}
		} catch (error) {
			dispatch(
				debitFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const flightMarupAmount = (bodyData) => async (dispatch) => {
	try {
		dispatch(markupRequest());

		const { data } = await axiosInstance.post(
			'flight/markuptopartner',
			bodyData,
			config
		);
		console.log(data);
		dispatch(markupSuccess(data));
	} catch (error) {
		dispatch(
			markupFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

// DOMESTIC ROUNDTRIP

export const flightDebitRoundtripAmount = (bodyData) => async (dispatch) => {
	try {
		dispatch(debitRoundtripRequest());

		const { data } = await axiosInstance.post(
			'flight/debitflightpayment',
			bodyData,
			config
		);
		console.log(data);
		dispatch(debitRoundtripSuccess(data));
		if (data.status === 200) {
		}
	} catch (error) {
		dispatch(
			debitRoundtripFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const TBOBookLCCONWARDDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(bookLCCONWARDRequest());
			const { data } = await axiosInstance.post(
				'/FlyTbo/flightbooklcc',
				bodyData,
				config
			);
			console.log('Data', data);
			dispatch(bookLCCONWARDSuccess(data));
			if (data.status === 200) {
				setLoading(false);
				dispatch(setModalPay(false));
				let markupData = {
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				dispatch(flightMarupAmount(markupData));
				navigate('/flightticket', { state: markupData });
			} else {
				setLoading(false);
				dispatch(setModalPay(false));
				alert('Booking Failed From Supplier End.Please try again.');
			}
		} catch (error) {
			dispatch(
				bookLCCONWARDFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const TBOBookGDSONWARDDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(bookGDSONWARDRequest());
			const { data } = await axiosInstance.post(
				'FlyTbo/flightbookgds',
				bodyData,
				config
			);
			dispatch(bookGDSONWARDSuccess(data));
			console.log('Data', data);
			if (data.status === 200) {
				let formData = {
					TraceId: bodyData.TraceId,
					PNR: data.result.Response.Response.PNR,
					BookingId: data.result.Response.Response.BookingId,
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				console.log('TicketGDS', formData);

				dispatch(
					FlightTBOTicketGDSONWARDDispatch(formData, setLoading, navigate)
				);
			} else {
				setLoading(false);
				dispatch(setModalPay(false));
				alert('Your session is expired.Please try again.');
				navigate('/flight');
			}
		} catch (error) {
			dispatch(
				bookGDSONWARDFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightTBOTicketGDSONWARDDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(ticketGDSONWARDRequest());
			console.log('ticketgdsprocess');
			const { data } = await axiosInstance.post(
				'FlyTbo/flightticketgds',
				bodyData,
				config
			);
			dispatch(ticketGDSONWARDSuccess(data));
			if (data.status === 200) {
				let markupData = {
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				dispatch(flightMarupAmount(markupData));
				setLoading(false);
				dispatch(setModalPay(false));
				navigate('/flightticket', { state: markupData });
			} else {
				setLoading(false);
				dispatch(setModalPay(false));
				alert('Booking Failed From Supplier End.Please try again.');
				// navigate('/flight');
			}
		} catch (error) {
			dispatch(
				ticketGDSONWARDFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

// RETURN

export const TBOBookLCCRETURNDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(bookLCCRETURNRequest());
			const { data } = await axiosInstance.post(
				'/FlyTbo/flightbooklcc',
				bodyData,
				config
			);
			console.log('Data', data);
			dispatch(bookLCCRETURNSuccess(data));
			if (data.status === 200) {
				setLoading(false);
				dispatch(setModalPay(false));
				let markupData = {
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				dispatch(flightMarupAmount(markupData));
				navigate('/flightticket', { state: markupData });
			} else {
				setLoading(false);
				dispatch(setModalPay(false));
				alert('Booking Failed From Supplier End.Please try again.');
				// navigate('/flight');
			}
		} catch (error) {
			dispatch(
				bookLCCRETURNFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const TBOBookGDSRETURNDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(bookGDSRETURNRequest());
			const { data } = await axiosInstance.post(
				'FlyTbo/flightbookgds',
				bodyData,
				config
			);
			dispatch(bookGDSRETURNSuccess(data));
			console.log('Data', data);
			if (data.status === 200) {
				let formData = {
					TraceId: bodyData.TraceId,
					PNR: data.result.Response.Response.PNR,
					BookingId: data.result.Response.Response.BookingId,
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				console.log('TicketGDS', formData);
				dispatch(
					FlightTBOTicketGDSRETURNDispatch(formData, setLoading, navigate)
				);
			} else {
				setLoading(false);
				dispatch(setModalPay(false));
				// alert('Your session is expired.Please try again.');
				// navigate('/flight');
			}
		} catch (error) {
			dispatch(
				bookGDSRETURNFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const FlightTBOTicketGDSRETURNDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(ticketGDSRETURNRequest());
			console.log('ticketgdsprocess');
			const { data } = await axiosInstance.post(
				'FlyTbo/flightticketgds',
				bodyData,
				config
			);
			dispatch(ticketGDSRETURNSuccess(data));
			if (data.status === 200) {
				let markupData = {
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				dispatch(flightMarupAmount(markupData));
				setLoading(false);
				dispatch(setModalPay(false));
				navigate('/flightticket', { state: markupData });
			} else {
				setLoading(false);
				dispatch(setModalPay(false));
				alert('Booking Failed From Supplier End.Please try again.');
				// navigate('/flight');
			}
		} catch (error) {
			dispatch(
				ticketGDSRETURNFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export default TBOReducer;
