import React, { Fragment } from 'react';
import footerlogo from '../../assets/images/footerlogo.svg';
import nounmail from '../../assets/images/nounmail.svg';
import nouncall from '../../assets/images/nouncall.svg';
import nounlocation from '../../assets/images/nounlocation.svg';
import groupF from '../../assets/images/GroupF.svg';
import grF from '../../assets/images/GrF.svg';
import logo from '../../assets/images/FAREEASE03.png';
import { Link } from 'react-router-dom';
import {
	Container,
	Button,
	Grid,
	Box,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import { Divider } from 'antd';

const Footer = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('lg'));
	return (
		<Fragment>
			{/* {matches ? (
				''
			) : (
				<Container
					style={{
						backgroundColor: 'rgb(253 121 14) ',
						position: 'absolute',
						marginTop: '40px',
						marginLeft: 150,
						borderRadius: 5,
					}}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<img src={groupF} />
						<div style={{ marginLeft: 30 }}>
							<h1 style={{ color: 'white', fontSize: 36 }}>
								Ready to adventure and enjoy natural
							</h1>
							<p style={{ color: 'rgba(255, 255, 255, 0.52)' }}>
							</p>{' '}
						</div>
						<img src={grF} />
						<span></span>
						<Button
							style={{
								backgroundColor: 'white',
								color: 'rgb(253 121 14) ',
								padding: 15,
								borderRadius: 25,
							}}>
							LET,s GET STARTED
						</Button>
					</div>
				</Container>
			)} */}
			<div
				style={{
					backgroundColor: 'rgba(29, 35, 31, 1)',
					// height: 300,
					// marginTop: 100,
				}}>
				<Container style={{ padding: 60 }}>
					<Grid container style={{}} spacing={4}>
						<Grid item xs={12} lg={3}>
							<Box>
								<img src={logo} />
								<p style={{ color: '#FFFFFF', fontSize: 14, marginTop: 20 }}>
									{/* MARINERS FOREX I Flights | Buses | Hotels | Tour & Forex
									Company */}
								</p>
								<Box display={'flex'} alignItems={'center'} mt={2}>
									<img src={nounmail} />
									<p style={{ marginLeft: 10, color: '#FFFFFF' }}>
										{/* easygetholidays@gmail.com */}
									</p>
								</Box>
								<Box display={'flex'} alignItems={'center'} mt={2}>
									<img src={nouncall} />
									<p style={{ marginLeft: 10, color: '#FFFFFF' }}>
										{/* +91 999-056-3308 */}
									</p>
								</Box>
								<Box display={'flex'} alignItems={'center'} mt={2}>
									<img src={nounlocation} />
									<p style={{ marginLeft: 10, color: '#FFFFFF' }}>
										1st Floor,New Tech Grand, Bank More, Dhanbad-826001
									</p>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} lg={2}>
							<Box>
								<h1 style={{ color: '#FFFFFF' }}>PRODUCTS</h1>
							</Box>

							<Box mt={2}>
								<Link to='/flight'>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Flight Booking
									</p>
								</Link>
							</Box>
							<Box mt={1.5}>
								<Link to='/hotel'>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Hotel Booking
									</p>
								</Link>
							</Box>
							<Box mt={1.5}>
								<Link to='/busbooking'>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>Bus Booking</p>
								</Link>
							</Box>
						</Grid>
						<Grid item xs={12} lg={3}>
							<Box>
								<h1 style={{ color: '#FFFFFF' }}>HOLIDAYS</h1>
							</Box>
							<Box mt={2}>
								<Link to='/companyinfo'>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>Company Info</p>
								</Link>
							</Box>
							<Link to='/privacypolicy'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Privacy Policy
									</p>
								</Box>
							</Link>
							<Link to='/companypolicy'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Company Policy
									</p>
								</Box>
							</Link>
							<Link to='/useragreement'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										User Agreement
									</p>
								</Box>
							</Link>
							<Link to='/termsandcondition'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Terms & Conditions
									</p>
								</Box>
							</Link>
							<Link to='/aboutus'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>About Us</p>
								</Box>
							</Link>
							<Link to='/careers'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>Careers</p>
								</Box>
							</Link>
							<Link to='/disclaimer'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>Disclaimer</p>
								</Box>
							</Link>
							<Link to='/contactus'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>Contact Us</p>
								</Box>
							</Link>
							<Link to='/baggageallowance'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Baggage Allowance
									</p>
								</Box>
							</Link>

							<Link to='/cancellationandrefund'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Cancellation/ Refund Policy
									</p>
								</Box>
							</Link>
						</Grid>
						<Grid item xs={12} lg={2}>
							<Box>
								<h1 style={{ color: '#FFFFFF' }}>SUPPORT</h1>
							</Box>

							<Link to='/customercare'>
								<Box mt={2}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Customer Care
									</p>
								</Box>
							</Link>
							<Link to='/feedbackform'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										FeedBack Form
									</p>
								</Box>
							</Link>
							<Link to='/faq'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>FAQ</p>
								</Box>
							</Link>
							<Link to='/contactus'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>Contact</p>
								</Box>
							</Link>
						</Grid>
						{/* <Grid item xs={12} lg={2}>
							<Box>
								<h1 style={{ color: '#FFFFFF' }}>MF DIRECTORY</h1>
							</Box>
							<Link to='/internationalAiprort'>
								<Box mt={2}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										International Airports
									</p>
								</Box>
							</Link>
							<Link to='/domesticairline'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Domestic Airlines
									</p>
								</Box>
							</Link>
							<Link to='/internationalairline'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										International Airlines
									</p>
								</Box>
							</Link>
							<Link to='/domesticflight'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Domestic Flights
									</p>
								</Box>
							</Link>
							<Link to='/internationalflight'>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										International Flights
									</p>
								</Box>
							</Link>
							<Box mt={1.5}>
								<p style={{ color: '#B4B4B4', fontSize: 14 }}>
									Airports In India
								</p>
							</Box>
							<Box mt={1.5}>
								<p style={{ color: '#B4B4B4', fontSize: 14 }}>
									Domestic Bus Routes
								</p>
							</Box>
							<Box mt={1.5}>
								<p style={{ color: '#B4B4B4', fontSize: 14 }}>
									International Hotels
								</p>
							</Box>
							<Box mt={1.5}>
								<p style={{ color: '#B4B4B4', fontSize: 14 }}>
									Airline Customer care
								</p>
							</Box>
						</Grid> */}
					</Grid>
					<Divider style={{ backgroundColor: '#F2F2F212' }} />
					<Box>
						<p style={{ color: '#FFFFFF', textAlign: 'center' }}>
							Copyright © 2024 by Fareease.in. All Rights Reserved
						</p>
					</Box>
				</Container>
			</div>
		</Fragment>
	);
};

export default Footer;
