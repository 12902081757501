import React, { useState } from 'react';
import { Grid, Box, Autocomplete, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedBus, setSelectedDesBus } from '../../reducers/UiReducer';
import bus from '../../assets/images/bus.avif';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

const dateFormat = 'DD-MM-YYYY';

const ModifyBusSearch = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [filterData, setFilterData] = useState('');
	const [filterData2, setFilterData2] = useState('');
	const { selectedBus, selectedDesBus } = useSelector((state) => state.ui);
	const { busCity } = useSelector((state) => state.bus);
	const [departDate, setDepartDate] = useState('');

	function disabledDate(current) {
		return moment().add(-1, 'days') >= current;
	}
	const onChange = (date, dateString) => {
		setDepartDate(dateString);
	};
	const handleChange = (event) => {
		let value = event ? event.target.value.toLowerCase() : 'del';
		let results = [];
		results =
			busCity &&
			busCity?.result &&
			busCity?.result?.cities?.filter((item) => {
				return (
					item.name.toLowerCase().includes(value.toLowerCase()) ||
					item.state.toLowerCase().includes(value.toLowerCase())
				);
			});
		console.log('searchresult', results);
		if (event) {
		} else {
			dispatch(setSelectedBus(results ? results[0] : null));
		}
		if (results?.length <= 80) {
			setFilterData(results);
		}
	};
	const handleChange2 = (event) => {
		let value = event ? event.target.value.toLowerCase() : 'kol';
		let results = [];
		results =
			busCity &&
			busCity?.result &&
			busCity?.result?.cities?.filter((item) => {
				return (
					item.name.toLowerCase().includes(value.toLowerCase()) ||
					item.state.toLowerCase().includes(value.toLowerCase())
				);
			});
		console.log('searchresult', results);
		if (event) {
		} else {
			dispatch(setSelectedDesBus(results ? results[0] : null));
		}
		if (results?.length <= 80) {
			setFilterData2(results);
		}
	};
	const handleBus = () => {
		let formData = {
			departure_city: selectedBus.name.split(' ')[0],
			arrival_city: selectedDesBus.name.split(' ')[0],
			sourceid: selectedBus.id,
			destinationid: selectedDesBus.id,
			dateofjourney: departDate ? departDate : location.state.dateofjourney,
		};
		navigate('/busresult', { state: formData });
	};
	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} lg={4}>
					<Box display={'flex'}>
						<Box style={{ width: '100%' }}>
							<Autocomplete
								required
								id='country-select-demo'
								value={selectedBus}
								sx={{
									width: '100%',
									marginLeft: 2,
									marginTop: 1,
									'& .MuiFormLabel-root': {
										// color: 'white',
									},
									'& .MuiInputBase-input': {
										// color: 'rgba(207, 201, 201, 1)',
									},
									'& .MuiSvgIcon-root': {
										// color: 'white',
									},
								}}
								options={filterData && filterData.length > 0 ? filterData : []}
								autoHighlight
								ListboxProps={{
									className: 'myCustomList',
								}}
								getOptionLabel={(option) => `${option.name} (${option.state})`}
								renderOption={(props, option) => (
									<Box
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
										component='li'
										sx={{
											'& > img': {
												mr: 2,
												flexShrink: 0,
												fontSize: 14,
											},
										}}
										{...props}>
										<Box>
											<Box display={'flex'}>
												<img src={bus} style={{ width: 20, height: 20 }} />
												<p style={{ fontSize: 13, marginLeft: 5 }}>
													{option.name} - {option.state}
												</p>
											</Box>
											<p
												style={{
													fontSize: 13,
													marginLeft: 25,

													textOverflow: 'ellipsis',
													overflow: 'hidden',
													width: '200px',
													whiteSpace: 'nowrap',
												}}>
												{option.state}
											</p>
										</Box>
									</Box>
								)}
								onChange={(event, value) => {
									dispatch(setSelectedBus(value));
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										id='standard-basic'
										variant='standard'
										onChange={handleChange}
										style={{ fontSize: 12 }}
										inputProps={{
											...params.inputProps,
											autoComplete: 'new-password', // disable autocomplete and autofill
										}}
									/>
								)}
							/>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} lg={4}>
					<Box display={'flex'}>
						<Box style={{ width: '100%' }}>
							<Autocomplete
								required
								id='country-select-demo'
								value={selectedDesBus}
								sx={{
									width: '100%',
									marginLeft: 2,
									marginTop: 1,
									'& .MuiFormLabel-root': {
										// color: 'white',
									},
									'& .MuiInputBase-input': {
										// color: 'rgba(207, 201, 201, 1)',
									},
									'& .MuiSvgIcon-root': {
										// color: 'white',
									},
								}}
								options={
									filterData2 && filterData2.length > 0 ? filterData2 : []
								}
								autoHighlight
								ListboxProps={{
									className: 'myCustomList',
								}}
								getOptionLabel={(option) => `${option.name} (${option.state})`}
								renderOption={(props, option) => (
									<Box
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
										component='li'
										sx={{
											'& > img': {
												mr: 2,
												flexShrink: 0,
												fontSize: 14,
											},
										}}
										{...props}>
										<Box>
											<Box display={'flex'}>
												<img src={bus} style={{ width: 20, height: 20 }} />
												<p style={{ fontSize: 13, marginLeft: 5 }}>
													{option.name} - {option.state}
												</p>
											</Box>
											<p
												style={{
													fontSize: 13,
													marginLeft: 25,

													textOverflow: 'ellipsis',
													overflow: 'hidden',
													width: '200px',
													whiteSpace: 'nowrap',
												}}>
												{option.state}
											</p>
										</Box>
									</Box>
								)}
								onChange={(event, value) => {
									dispatch(setSelectedDesBus(value));
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										id='standard-basic'
										variant='standard'
										onChange={handleChange2}
										style={{ fontSize: 12 }}
										inputProps={{
											...params.inputProps,
											autoComplete: 'new-password', // disable autocomplete and autofill
										}}
									/>
								)}
							/>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} lg={2.5}>
					<Box display={'flex'} style={{ width: '100%' }}>
						<Box className='picker'>
							<DatePicker
								disabledDate={disabledDate}
								onChange={onChange}
								placeholder=''
								defaultValue={
									location.state
										? dayjs(
												`${location.state.dateofjourney.split('-')[2]}-${
													location.state.dateofjourney.split('-')[1]
												}-${location.state.dateofjourney.split('-')[0]}`,
												dateFormat
										  )
										: ''
								}
								format={dateFormat}
								style={{
									width: '100%',
									padding: 8,
									border: 'none',
									borderBottom: '1px solid',
									borderRadius: '1px',
									boxShadow: 'none',
									background: 'transparent',
								}}
							/>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} lg={1.5}>
					<Box
						onClick={handleBus}
						style={{
							backgroundColor: 'rgb(253 121 14) ',
							textAlign: 'center',
							padding: '10px',
							borderRadius: '5px',
							cursor: 'pointer',
							color: 'white',
						}}
						textAlign={'center'}>
						Search
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default ModifyBusSearch;
