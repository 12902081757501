import React, { useState } from 'react';

const SpecialFare = () => {
	const [selectedFare, setSelectedFare] = useState('regular');

	// Fare options
	const fares = [
		{ id: 'regular', label: 'Regular Fare' },
		{ id: 'student', label: 'Student Fare' },
		{ id: 'Senior', label: 'Senior Citizen' },
		{ id: 'Armed', label: 'Armed Forces' },
	];

	const handleSelection = (fareId) => {
		setSelectedFare(fareId);
	};

	return (
		<div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
			<h3 style={{ color: 'white', fontWeight: 'bold' }}>
				Select a Special Fare
			</h3>
			<div style={{ display: 'flex', gap: '10px', marginTop: 20 }}>
				{fares.map((fare) => (
					<button
						key={fare.id}
						onClick={() => handleSelection(fare.id)}
						style={{
							padding: '10px 20px',
							border: '1px solid rgb(253, 121, 14)',
							borderRadius: '25px',
							backgroundColor:
								selectedFare === fare.id ? 'rgb(253, 121, 14)' : '#fff',
							color: selectedFare === fare.id ? '#fff' : '#333',
							cursor: 'pointer',
							fontSize: 14,
						}}>
						{fare.label}
					</button>
				))}
			</div>

			{/* <div style={{ marginTop: '20px' }}>
				<strong>Selected Fare:</strong>{' '}
				{fares.find((fare) => fare.id === selectedFare)?.label}
			</div> */}
		</div>
	);
};

export default SpecialFare;
