import React, { useEffect, useState } from 'react';
import Footer from '../components/resuableComponents/Footer';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FlightRow from '../components/resuableComponents/FlightRow';
import Skeleton from '@mui/material/Skeleton';
import FlightRowSkeleton from '../components/resuableComponents/FlightRowSkeleton';
import FilterSection from '../components/resuableComponents/FilterSection';
import { addSearchFlightDispatch } from '../reducers/TBOReducer';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import HeaderMobileWeb from '../components/resuableComponents/Navbar/HeaderMobileWeb';
import ModifySearch from '../components/resuableComponents/ModifySearch';

const FlightResult = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { tbo, loading, tbo2 } = useSelector((state) => state.tbo);
	const [result, setResult] = useState([]);
	const [rule, SetRule] = useState(false);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	console.log('location', location);
	useEffect(() => {
		window.scrollTo(0, 0);
		if (location.state) {
			dispatch(addSearchFlightDispatch(location.state));
			setResult([]);
		}
	}, [location.state]);
	let filterData = [];
	let combineflightData = [];
	if (loading) {
		console.log('Loading');
	} else {
		let tbores =
			tbo &&
			tbo?.result &&
			tbo?.result?.Response &&
			tbo?.result?.Response?.Results[0].length > 0 &&
			tbo?.result?.Response?.Results[0];
		let tbores2 =
			tbo2 &&
			tbo2?.result &&
			tbo2?.result?.Response &&
			tbo2?.result?.Response?.Results[0].length > 0 &&
			tbo2?.result?.Response?.Results[0];
		if (tbores?.length > 0 && tbores2?.length > 0) {
			combineflightData = [...tbores, ...tbores2];
		} else if (tbores?.length > 0) {
			combineflightData = [...tbores];
		} else if (tbores2?.length > 0) {
			combineflightData = [...tbores2];
		}
		filterData =
			combineflightData &&
			combineflightData.length > 0 &&
			combineflightData.slice().sort((a, b) => {
				let first =
					a.Supplier === 'TBO'
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: a.Supplier === 'MFB'
						? Number(Math.round(a.Fare.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
				let second =
					b.Supplier === 'TBO'
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: b.Supplier === 'MFB'
						? Number(Math.round(b.Fare.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
				return first - second;
			});
	}

	return (
		<>
			<Navbar />
			<MNavbar />
			{matches ? (
				<HeaderMobileWeb active='3' setResult={setResult} SetRule={SetRule} />
			) : (
				''
			)}
			{matches ? '' : <ModifySearch />}
			<Box style={{ padding: matches ? '0px' : '20px 90px' }}>
				<Grid container spacing={matches ? '' : 4}>
					<Grid item xs={3}>
						{matches ? (
							''
						) : (
							<FilterSection setResult={setResult} SetRule={SetRule} />
						)}
					</Grid>
					<Grid item xs={12} lg={9}>
						{matches ? (
							<>
								<Box
									display='flex'
									justifyContent={'space-between'}
									style={{ padding: 20 }}>
									<Box>
										<h1
											style={{
												fontWeight: 'bold',
											}}>{`${location.state.Segments[0].Origin}-${location.state.Segments[0].Destination}`}</h1>
										<h1 style={{ fontWeight: 'bold', fontSize: 12 }}>
											{location.state.Segments[0].PreferredDepartureTime}
										</h1>
									</Box>

									<h1 style={{ fontWeight: 'bold' }}>Modify</h1>
								</Box>
							</>
						) : (
							<Box
								display={'flex'}
								justifyContent={'space-between'}
								style={{
									background: 'rgba(241, 250, 239, 1)',
									padding: 15,
									borderRadius: 12,
								}}>
								{loading ? (
									<Skeleton animation='wave' width={50} />
								) : (
									<Box style={{ fontWeight: 'bold' }}>Sort by:</Box>
								)}
								{loading ? (
									<Skeleton animation='wave' width={50} />
								) : (
									<Box
										style={{
											fontWeight: 'bold',
											color: 'rgba(243, 106, 0, 1)',
										}}>
										{/* Departure */}
									</Box>
								)}
								{loading ? (
									<Skeleton animation='wave' width={50} />
								) : (
									<Box style={{ fontWeight: 'bold' }}>{/* Fastest */}</Box>
								)}
								{loading ? (
									<Skeleton animation='wave' width={50} />
								) : (
									<Box style={{ fontWeight: 'bold' }}>{/* Price */}</Box>
								)}
								{loading ? (
									<Skeleton animation='wave' width={50} />
								) : (
									<Box style={{ fontWeight: 'bold' }}>{/* Recommanded */}</Box>
								)}
							</Box>
						)}
						{!loading ? (
							rule ? (
								result?.length > 0 ? (
									result?.map((item) => {
										return <FlightRow item={item} />;
									})
								) : (
									<p
										style={{
											marginTop: 20,
											textAlign: 'center',
											fontSize: 16,
										}}>
										No Flight Found
									</p>
								)
							) : (
								filterData.length > 0 &&
								filterData.map((item) => {
									return <FlightRow item={item} />;
								})
							)
						) : (
							Array.from(Array(10)).map((i) => {
								return <FlightRowSkeleton />;
							})
						)}
					</Grid>
				</Grid>
			</Box>
			<Footer />
		</>
	);
};

export default FlightResult;
