import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Box, Grid, Button } from '@mui/material';
import { DatePicker, Divider } from 'antd';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import FirstNameFormatModal from '../components/resuableComponents/FirstNameFormatModal';
import LastNameFormatModal from '../components/resuableComponents/LastNameFormatModal';
const title = ['', 'Mr', 'Mrs', 'Ms', 'Mstr', 'Miss'];
const dateFormat = 'YYYY-MM-DD';

const FlightSummaryForm = () => {
	const location = useLocation();
	const naviagte = useNavigate();
	const [value, setValue] = useState(0);
	const [value2, setValue2] = useState(0);
	const [value3, setValue3] = useState(0);
	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);
	const handleOpen = () => setOpen(true);
	const [open2, setOpen2] = useState(false);
	const handleClose2 = () => setOpen2(false);
	const handleOpen2 = () => setOpen2(true);
	const { depart, arrival } = useSelector((state) => state.ui);
	const handleFormSubmit = (data) => {
		console.log('Data', data);
		if (location.state.formData.JourneyType === 1) {
			naviagte('/flightconfirm', {
				state: {
					item: location.state.item,
					passengerData: data,
					formData: location.state.formData,
					traceId: location.state.traceId,
					orderId: location.state.orderId,
				},
			});
		} else {
			if (depart.country_code === 'IN' && arrival.country_code === 'IN') {
				naviagte('/flightconfirm', {
					state: {
						onwardFlight: location.state.onwardFlight,
						returnFlight: location.state.returnFlight,
						formData: location.state.formData,
						traceId: location.state.traceId,
						orderId: location.state.orderId,
						passengerData: data,
					},
				});
			} else {
				naviagte('/flightconfirm', {
					state: {
						item: location.state.item,
						passengerData: data,
						formData: location.state.formData,
						traceId: location.state.traceId,
						orderId: location.state.orderId,
						passengerData: data,
					},
				});
			}
		}
	};
	function disabledDate(current) {
		return current && current < moment().endOf('day');
	}
	let arr = [];
	let arr2 = [];
	let arr3 = [];
	for (let i = 1; i <= parseInt(location.state.formData.AdultCount); i++) {
		if (depart.country_code === 'IN' && arrival.country_code === 'IN') {
			arr.push({ title: '', firstname: '', lastname: '', gender: '' });
		} else {
			arr.push({
				title: '',
				firstname: '',
				lastname: '',
				passportnumber: '',
				expiry: '',
				dob: '',
				gender: '',
			});
		}
	}
	for (let i = 1; i <= parseInt(location.state.formData.ChildCount); i++) {
		if (depart.country_code === 'IN' && arrival.country_code === 'IN') {
			arr2.push({
				title: '',
				firstname: '',
				lastname: '',
				dob: '',
				gender: '',
			});
		} else {
			arr2.push({
				title: '',
				firstname: '',
				lastname: '',
				passportnumber: '',
				expiry: '',
				dob: '',
				gender: '',
			});
		}
	}
	for (let i = 1; i <= parseInt(location.state.formData.InfantCount); i++) {
		if (depart.country_code === 'IN' && arrival.country_code === 'IN') {
			arr3.push({
				title: '',
				firstname: '',
				lastname: '',
				dob: '',
				gender: '',
			});
		} else {
			arr3.push({
				title: '',
				firstname: '',
				lastname: '',
				passportnumber: '',
				expiry: '',
				dob: '',
				gender: '',
			});
		}
	}

	return (
		<>
			<Formik
				initialValues={{
					email: '',
					phone: '',
					adult: arr,
					child: arr2,
					infant: arr3,
				}}
				validationSchema={
					depart.country_code === 'IN' && arrival.country_code === 'IN'
						? Yup.object({
								email: Yup.string()
									.email('Invalid email')
									.required('Email Required'),
								phone: Yup.string().required('Phone required').max(10),
								adult: Yup.array().of(
									Yup.object().shape({
										firstname: Yup.string().required('firstname required'),
										lastname: Yup.string().required('lastname required'),
										title: Yup.string().required('title required'),
										gender: Yup.string().required('gender required'),
									})
								),
								child: Yup.array().of(
									Yup.object().shape({
										firstname: Yup.string().required('firstname required'),
										lastname: Yup.string().required('lastname required'),
										title: Yup.string().required('title required'),
										gender: Yup.string().required('gender required'),
										dob: Yup.string().required('Date of Birth required'),
									})
								),
								infant: Yup.array().of(
									Yup.object().shape({
										firstname: Yup.string().required('firstname required'),
										lastname: Yup.string().required('lastname required'),
										title: Yup.string().required('title required'),
										gender: Yup.string().required('gender required'),
										dob: Yup.string().required('Date of Birth required'),
									})
								),
						  })
						: Yup.object({
								email: Yup.string()
									.email('Invalid email')
									.required('Email Required'),
								phone: Yup.string().required('Phone required').max(10),
								adult: Yup.array().of(
									Yup.object().shape({
										firstname: Yup.string().required('firstname required'),
										lastname: Yup.string().required('lastname required'),
										title: Yup.string().required('title required'),
										gender: Yup.string().required('gender required'),
										passportnumber: Yup.string().required(
											'passportnumber  required'
										),
										expiry: Yup.string().required('Passport expiry required'),
										dob: Yup.string().required('Date of Birth required'),
									})
								),
								child: Yup.array().of(
									Yup.object().shape({
										firstname: Yup.string().required('firstname required'),
										lastname: Yup.string().required('lastname required'),
										title: Yup.string().required('title required'),
										passportnumber: Yup.string().required(
											'passportnumber  required'
										),
										expiry: Yup.string().required('Passport expiry required'),
										dob: Yup.string().required('Date of Birth required'),
									})
								),
								infant: Yup.array().of(
									Yup.object().shape({
										firstname: Yup.string().required('firstname required'),
										lastname: Yup.string().required('lastname required'),
										title: Yup.string().required('title required'),
										passportnumber: Yup.string().required(
											'passportnumber  required'
										),
										expiry: Yup.string().required('Passport expiry required'),
										dob: Yup.string().required('Date of Birth required'),
									})
								),
						  })
				}
				onSubmit={handleFormSubmit}>
				{(formik, values) => {
					const { errors, setFieldValue } = formik;
					console.log(
						'first',
						Object?.keys(errors)?.length === 0 && errors?.constructor === Object
					);
					console.log('values', formik?.values);
					console.log('errors', errors);
					return (
						<Form>
							<Box display={'flex'} justifyContent={'space-between'} mt={2}>
								<Button
									onClick={handleOpen}
									style={{
										color: 'rgb(8 128 130)',
										fontSize: 10,
										border: '1px solid rgb(8 128 130)',
									}}>
									First Name Format
								</Button>

								<Button
									onClick={handleOpen2}
									style={{
										color: 'rgb(8 128 130)',
										fontSize: 10,
										border: '1px solid rgb(8 128 130)',
										marginLeft: 10,
									}}>
									Last Name Format
								</Button>
							</Box>
							<Box
								mt={2}
								style={{
									boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
								}}>
								<div
									style={{
										background: 'rgba(237, 240, 233, 1)',
										padding: 12,
										borderTopLeftRadius: 5,
										borderTopRightRadius: 5,
									}}>
									Contact Information
								</div>
								<Grid
									container
									style={{ marginTop: 5, padding: 10 }}
									spacing={4}>
									<Grid item xs={12} lg={6}>
										<Field
											name='email'
											type='text'
											placeHolder='Email'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.email
													? '2px solid red'
													: '1.33px solid rgba(203, 203, 203, 1)',
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.email ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.email}
											</span>
										) : null}
									</Grid>
									<Grid item xs={12} lg={6}>
										<Field
											name='phone'
											type='text'
											placeHolder='Phone'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.phone
													? '2px solid red'
													: '1.33px solid rgba(203, 203, 203, 1)',
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.phone ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.phone}
											</span>
										) : null}
									</Grid>
								</Grid>
							</Box>

							<div
								style={{
									background: 'rgba(237, 240, 233, 1)',
									padding: 12,
									borderTopLeftRadius: 5,
									borderTopRightRadius: 5,
									marginTop: 20,
								}}>
								Traveller Details
							</div>

							<Box
								style={{
									boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
									padding: 20,
								}}>
								<FieldArray
									name='adult'
									render={(arrayHelpers) => {
										console.log('arrayHelpers', arrayHelpers);
										return (
											<div>
												{formik?.values?.adult?.map((adults, index) => (
													<>
														<Box
															style={{
																marginTop: 20,
																border: '1.33px solid rgba(203, 203, 203, 1)',
																padding: 20,
																borderRadius: 5,
															}}>
															<Box
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	alignItems: 'center',
																}}>
																<Box
																	style={{ display: 'flex' }}
																	alignItems={'center'}>
																	{Object?.keys(errors)?.length === 0 &&
																	errors?.constructor === Object ? (
																		<svg
																			focusable='false'
																			color='inherit'
																			fill='none'
																			aria-hidden='true'
																			role='presentation'
																			viewBox='0 0 20 21'
																			preserveAspectRatio='xMidYMid meet'
																			width='24px'
																			height='24px'
																			class='sc-hMqMXs llihTZ'>
																			<path
																				d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																				fill='rgb(253 121 14) '></path>
																		</svg>
																	) : errors?.adult ? (
																		errors?.adult?.length > 0 ? (
																			errors?.adult[index] === undefined ? (
																				<svg
																					focusable='false'
																					color='inherit'
																					fill='none'
																					aria-hidden='true'
																					role='presentation'
																					viewBox='0 0 20 21'
																					preserveAspectRatio='xMidYMid meet'
																					width='24px'
																					height='24px'
																					class='sc-hMqMXs llihTZ'>
																					<path
																						d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																						fill='rgb(253 121 14) '></path>
																				</svg>
																			) : (
																				<svg
																					focusable='false'
																					color='grey'
																					fill='rgb(216, 220, 222)'
																					aria-hidden='true'
																					role='presentation'
																					viewBox='0 0 20 21'
																					preserveAspectRatio='xMidYMid meet'
																					width='24px'
																					height='24px'
																					class='sc-hMqMXs llihTZ'>
																					<path
																						d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																						fill='rgb(216, 220, 222)'></path>
																				</svg>
																			)
																		) : (
																			<svg
																				focusable='false'
																				color='inherit'
																				fill='none'
																				aria-hidden='true'
																				role='presentation'
																				viewBox='0 0 20 21'
																				preserveAspectRatio='xMidYMid meet'
																				width='24px'
																				height='24px'
																				class='sc-hMqMXs llihTZ'>
																				<path
																					d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																					fill='rgb(253 121 14) '></path>
																			</svg>
																		)
																	) : (
																		<svg
																			focusable='false'
																			color='inherit'
																			fill='none'
																			aria-hidden='true'
																			role='presentation'
																			viewBox='0 0 20 21'
																			preserveAspectRatio='xMidYMid meet'
																			width='24px'
																			height='24px'
																			class='sc-hMqMXs llihTZ'>
																			<path
																				d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																				fill='rgb(253 121 14) '></path>
																		</svg>
																	)}

																	<div style={{ marginLeft: 5 }}>
																		{formik?.values?.adult[index].title &&
																		formik?.values?.adult[index].firstname &&
																		formik?.values?.adult[index].lastname
																			? `${formik?.values?.adult[index].title} ${formik?.values?.adult[index].firstname} ${formik?.values?.adult[index].lastname}`
																			: `Adult ${index + 1}`}
																	</div>
																</Box>
																<svg
																	style={{ cursor: 'pointer' }}
																	onClick={() =>
																		value === index
																			? setValue(-1)
																			: setValue(index)
																	}
																	focusable='false'
																	color='secondaryDark'
																	fill='currentcolor'
																	aria-hidden='true'
																	role='presentation'
																	viewBox='0 0 150 150'
																	preserveAspectRatio='xMidYMid meet'
																	size='9'
																	width='9'
																	height='9'
																	class='sc-hMqMXs gDtCsn'>
																	<path d='M86.8 118.6l60.1-61.5c5.5-5.7 5.5-14.8 0-20.5l-5-5.1c-5.4-5.5-14.3-5.6-19.8-.2l-.2.2L76 78.4 30.1 31.5c-5.4-5.5-14.3-5.6-19.8-.2l-.2.2-5 5.1c-5.5 5.7-5.5 14.8 0 20.5l60.1 61.5c2.8 2.9 6.8 4.4 10.8 4.1 4 .3 8-1.3 10.8-4.1z'></path>
																</svg>
															</Box>

															{index === value ? (
																<>
																	<Box display={'flex'} mt={2} ml={1.5} mb={2}>
																		<Box
																			onClick={() => {
																				setFieldValue(
																					`adult.${index}.gender`,
																					'Male'
																				);
																				console.log(
																					'formikvalues',
																					formik?.values.adult[index].gender
																				);
																			}}
																			style={{
																				padding: '10px 30px',
																				borderTopLeftRadius: 5,
																				borderBottomLeftRadius: 5,
																				background:
																					formik?.values.adult[index].gender ===
																					'Male'
																						? 'rgb(8 128 130)'
																						: '',
																				color:
																					formik?.values.adult[index].gender ===
																					'Male'
																						? 'white'
																						: '',
																				cursor: 'pointer',
																				border:
																					Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object
																						? '1.33px solid rgba(203, 203, 203, 1) '
																						: errors?.adult
																						? errors?.adult[index]?.gender
																							? '2px solid red'
																							: '1.33px solid rgba(203, 203, 203, 1) '
																						: '1.33px solid rgba(203, 203, 203, 1)',
																			}}>
																			MALE
																		</Box>
																		<Box
																			onClick={() => {
																				setFieldValue(
																					`adult.${index}.gender`,
																					'Female'
																				);
																				console.log(
																					'formikvalues',
																					formik?.values.adult[index].gender
																				);
																			}}
																			style={{
																				// border:
																				// 	'1.33px solid rgba(203, 203, 203, 1)',
																				padding: '10px 30px',
																				borderTopRightRadius: 5,
																				borderBottomRightRadius: 5,
																				background:
																					formik?.values.adult[index].gender ===
																					'Female'
																						? 'rgb(8 128 130)'
																						: '',
																				color:
																					formik?.values.adult[index].gender ===
																					'Female'
																						? 'white'
																						: '',
																				cursor: 'pointer',
																				border:
																					Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object
																						? '1.33px solid rgba(203, 203, 203, 1) '
																						: errors?.adult
																						? errors?.adult[index]?.gender
																							? '2px solid red'
																							: '1.33px solid rgba(203, 203, 203, 1) '
																						: '1.33px solid rgba(203, 203, 203, 1)',
																			}}>
																			FEMALE
																		</Box>
																	</Box>
																	<Grid
																		container
																		style={{ padding: 10 }}
																		spacing={4}>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`adult.${index}.title`}
																				as='select'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1)'
																							: errors?.adult
																							? errors?.adult[index]?.title
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1)'
																							: '1.33px solid rgba(203, 203, 203, 1)',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}>
																				{title.map((item, index) => (
																					<option value={item}>{item}</option>
																				))}
																			</Field>
																			{Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.adult ? (
																				errors?.adult[index]?.title ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.adult[index]?.title}
																					</span>
																				) : null
																			) : (
																				''
																			)}
																		</Grid>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`adult.${index}.firstname`}
																				type='text'
																				placeHolder='First Name'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1) '
																							: errors?.adult
																							? errors?.adult[index]?.firstname
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1) '
																							: '1.33px solid rgba(203, 203, 203, 1)',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}
																			/>
																			<br />
																			{Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.adult ? (
																				errors?.adult[index]?.firstname ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.adult[index]?.firstname}
																					</span>
																				) : null
																			) : (
																				''
																			)}
																		</Grid>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`adult.${index}.lastname`}
																				type='text'
																				placeHolder='Last Name'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1) '
																							: errors?.adult
																							? errors?.adult[index]?.lastname
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1) '
																							: '1.33px solid rgba(203, 203, 203, 1)',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}
																			/>
																			<br />
																			{Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.adult ? (
																				errors?.adult[index]?.lastname ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.adult[index]?.lastname}
																					</span>
																				) : null
																			) : (
																				''
																			)}
																		</Grid>
																		{depart.country_code === 'IN' &&
																		arrival.country_code === 'IN' ? (
																			''
																		) : (
																			<>
																				<Grid item xs={12} lg={6}>
																					<Field
																						name={`adult.${index}.passportnumber`}
																						type='text'
																						placeHolder='Passport Number'
																						style={{
																							padding: 10,
																							width: '100%',
																							borderRadius: 5,
																							border:
																								Object?.keys(errors)?.length ===
																									0 &&
																								errors?.constructor === Object
																									? '1.33px solid rgba(203, 203, 203, 1) '
																									: errors?.adult
																									? errors?.adult[index]
																											?.passportnumber
																										? '2px solid red'
																										: '1.33px solid rgba(203, 203, 203, 1) '
																									: '1.33px solid rgba(203, 203, 203, 1)',
																						}}
																						inputProps={{
																							style: { padding: 12 },
																						}}
																					/>
																					<br />
																					{Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object ? (
																						''
																					) : errors?.adult ? (
																						errors?.adult[index]
																							?.passportnumber ? (
																							<span
																								style={{
																									color: 'red',
																									fontSize: 12,
																								}}>
																								{
																									errors?.adult[index]
																										?.passportnumber
																								}
																							</span>
																						) : null
																					) : (
																						''
																					)}
																				</Grid>
																				<Grid
																					item
																					xs={12}
																					lg={6}
																					className='expiry'>
																					<div
																						style={{
																							marginBottom: 5,
																							fontSize: 14,
																						}}>
																						Select Passport Expiry
																					</div>

																					<DatePicker
																						disabledDate={disabledDate}
																						name={`adult.${index}.expiry`}
																						defaultValue={
																							formik?.values.adult[index].expiry
																								? dayjs(
																										formik?.values.adult[index]
																											.expiry,
																										dateFormat
																								  )
																								: ''
																						}
																						style={{
																							padding: 10,
																							width: '100%',
																							borderRadius: 5,
																							border:
																								Object?.keys(errors)?.length ===
																									0 &&
																								errors?.constructor === Object
																									? '1.33px solid rgba(203, 203, 203, 1) '
																									: errors?.adult
																									? errors?.adult[index]?.expiry
																										? '2px solid red'
																										: '1.33px solid rgba(203, 203, 203, 1)'
																									: '1.33px solid rgba(203, 203, 203, 1)',
																						}}
																						onChange={(date, dateString) => {
																							setFieldValue(
																								`adult.${index}.expiry`,
																								dateString
																							);
																						}}
																					/>
																					<br />
																					{Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object ? (
																						''
																					) : errors?.adult ? (
																						errors?.adult[index]?.expiry ? (
																							<span
																								style={{
																									color: 'red',
																									fontSize: 12,
																								}}>
																								{errors?.adult[index]?.expiry}
																							</span>
																						) : null
																					) : (
																						''
																					)}
																				</Grid>
																				<Grid
																					item
																					xs={12}
																					lg={6}
																					className='birth'>
																					<div
																						style={{
																							marginBottom: 5,
																							fontSize: 14,
																						}}>
																						Select Date of Birth
																					</div>

																					<DatePicker
																						name={`adult.${index}.dob`}
																						defaultValue={
																							formik?.values.adult[index].dob
																								? dayjs(
																										formik?.values.adult[index]
																											.dob,
																										dateFormat
																								  )
																								: ''
																						}
																						style={{
																							padding: 10,
																							width: '100%',
																							borderRadius: 5,
																							color: 'black',
																							border:
																								Object?.keys(errors)?.length ===
																									0 &&
																								errors?.constructor === Object
																									? '1.33px solid rgba(203, 203, 203, 1) '
																									: errors?.adult
																									? errors?.adult[index]?.dob
																										? '2px solid red'
																										: '1.33px solid rgba(203, 203, 203, 1) '
																									: '1.33px solid rgba(203, 203, 203, 1) ',
																						}}
																						onChange={(date, dateString) => {
																							setFieldValue(
																								`adult.${index}.dob`,
																								dateString
																							);
																						}}
																					/>
																					<br />
																					{Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object ? (
																						''
																					) : errors?.adult ? (
																						errors?.adult[index]?.dob ? (
																							<span
																								style={{
																									color: 'red',
																									fontSize: 12,
																								}}>
																								{errors?.adult[index]?.dob}
																							</span>
																						) : null
																					) : (
																						''
																					)}
																				</Grid>
																			</>
																		)}
																	</Grid>
																</>
															) : (
																''
															)}
														</Box>
													</>
												))}
											</div>
										);
									}}
								/>
								<Divider />
								<FieldArray
									name='child'
									render={(arrayHelpers) => {
										return (
											<div>
												{formik?.values?.child?.map((adults, index) => (
													<>
														<Box
															style={{
																marginTop: 20,
																border: '1.33px solid rgba(203, 203, 203, 1)',
																padding: 20,
																borderRadius: 5,
															}}>
															<Box
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	alignItems: 'center',
																}}>
																<Box style={{ display: 'flex' }}>
																	{Object?.keys(errors)?.length === 0 &&
																	errors?.constructor === Object ? (
																		<svg
																			focusable='false'
																			color='inherit'
																			fill='none'
																			aria-hidden='true'
																			role='presentation'
																			viewBox='0 0 20 21'
																			preserveAspectRatio='xMidYMid meet'
																			width='24px'
																			height='24px'
																			class='sc-hMqMXs llihTZ'>
																			<path
																				d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																				fill='rgb(253 121 14) '></path>
																		</svg>
																	) : errors?.child ? (
																		errors?.child?.length > 0 ? (
																			errors?.child[index] === undefined ? (
																				<svg
																					focusable='false'
																					color='inherit'
																					fill='none'
																					aria-hidden='true'
																					role='presentation'
																					viewBox='0 0 20 21'
																					preserveAspectRatio='xMidYMid meet'
																					width='24px'
																					height='24px'
																					class='sc-hMqMXs llihTZ'>
																					<path
																						d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																						fill='rgb(253 121 14) '></path>
																				</svg>
																			) : (
																				<svg
																					focusable='false'
																					color='grey'
																					fill='rgb(216, 220, 222)'
																					aria-hidden='true'
																					role='presentation'
																					viewBox='0 0 20 21'
																					preserveAspectRatio='xMidYMid meet'
																					width='24px'
																					height='24px'
																					class='sc-hMqMXs llihTZ'>
																					<path
																						d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																						fill='rgb(216, 220, 222)'></path>
																				</svg>
																			)
																		) : (
																			<svg
																				focusable='false'
																				color='inherit'
																				fill='none'
																				aria-hidden='true'
																				role='presentation'
																				viewBox='0 0 20 21'
																				preserveAspectRatio='xMidYMid meet'
																				width='24px'
																				height='24px'
																				class='sc-hMqMXs llihTZ'>
																				<path
																					d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																					fill='rgb(253 121 14) '></path>
																			</svg>
																		)
																	) : (
																		<svg
																			focusable='false'
																			color='inherit'
																			fill='none'
																			aria-hidden='true'
																			role='presentation'
																			viewBox='0 0 20 21'
																			preserveAspectRatio='xMidYMid meet'
																			width='24px'
																			height='24px'
																			class='sc-hMqMXs llihTZ'>
																			<path
																				d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																				fill='rgb(253 121 14) '></path>
																		</svg>
																	)}

																	<div style={{ marginLeft: 5 }}>
																		{formik?.values?.child[index].title &&
																		formik?.values?.child[index].firstname &&
																		formik?.values?.child[index].lastname
																			? `${formik?.values?.child[index].title} ${formik?.values?.child[index].firstname} ${formik?.values?.adult[index].lastname}`
																			: `Child ${index + 1}`}
																	</div>
																</Box>
																<svg
																	style={{ cursor: 'pointer' }}
																	onClick={() =>
																		value2 === index
																			? setValue2(-1)
																			: setValue2(index)
																	}
																	focusable='false'
																	color='secondaryDark'
																	fill='currentcolor'
																	aria-hidden='true'
																	role='presentation'
																	viewBox='0 0 150 150'
																	preserveAspectRatio='xMidYMid meet'
																	size='9'
																	width='9'
																	height='9'
																	class='sc-hMqMXs gDtCsn'>
																	<path d='M86.8 118.6l60.1-61.5c5.5-5.7 5.5-14.8 0-20.5l-5-5.1c-5.4-5.5-14.3-5.6-19.8-.2l-.2.2L76 78.4 30.1 31.5c-5.4-5.5-14.3-5.6-19.8-.2l-.2.2-5 5.1c-5.5 5.7-5.5 14.8 0 20.5l60.1 61.5c2.8 2.9 6.8 4.4 10.8 4.1 4 .3 8-1.3 10.8-4.1z'></path>
																</svg>
															</Box>

															{index === value2 ? (
																<>
																	<Box display={'flex'} mt={2} ml={1.5} mb={2}>
																		<Box
																			onClick={() => {
																				setFieldValue(
																					`child.${index}.gender`,
																					'Male'
																				);
																				console.log(
																					'formikvalues',
																					formik?.values.child[index].gender
																				);
																			}}
																			style={{
																				padding: '10px 30px',
																				borderTopLeftRadius: 5,
																				borderBottomLeftRadius: 5,
																				background:
																					formik?.values.child[index].gender ===
																					'Male'
																						? 'rgb(8 128 130)'
																						: '',
																				color:
																					formik?.values.child[index].gender ===
																					'Male'
																						? 'white'
																						: '',
																				cursor: 'pointer',
																				border:
																					Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object
																						? '1.33px solid rgba(203, 203, 203, 1) '
																						: errors?.child
																						? errors?.child[index]?.gender
																							? '2px solid red'
																							: '1.33px solid rgba(203, 203, 203, 1) '
																						: '1.33px solid rgba(203, 203, 203, 1)',
																			}}>
																			MALE
																		</Box>
																		<Box
																			onClick={() => {
																				setFieldValue(
																					`child.${index}.gender`,
																					'Female'
																				);
																				console.log(
																					'formikvalues',
																					formik?.values.child[index].gender
																				);
																			}}
																			style={{
																				// border:
																				// 	'1.33px solid rgba(203, 203, 203, 1)',
																				padding: '10px 30px',
																				borderTopRightRadius: 5,
																				borderBottomRightRadius: 5,
																				background:
																					formik?.values.child[index].gender ===
																					'Female'
																						? 'rgb(8 128 130)'
																						: '',
																				color:
																					formik?.values.child[index].gender ===
																					'Female'
																						? 'white'
																						: '',
																				cursor: 'pointer',
																				border:
																					Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object
																						? '1.33px solid rgba(203, 203, 203, 1) '
																						: errors?.child
																						? errors?.child[index]?.gender
																							? '2px solid red'
																							: '1.33px solid rgba(203, 203, 203, 1) '
																						: '1.33px solid rgba(203, 203, 203, 1) ',
																			}}>
																			FEMALE
																		</Box>
																	</Box>
																	<Grid
																		container
																		style={{ marginTop: 5 }}
																		spacing={2}>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`child.${index}.title`}
																				as='select'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1) '
																							: errors?.child
																							? errors?.child[index]?.title
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1) '
																							: '1.33px solid rgba(203, 203, 203, 1)',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}>
																				{title.map((item, index) => (
																					<option value={item}>{item}</option>
																				))}
																			</Field>
																			{Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.child ? (
																				errors?.child[index]?.title ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.child[index]?.title}
																					</span>
																				) : null
																			) : (
																				''
																			)}
																		</Grid>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`child.${index}.firstname`}
																				type='text'
																				placeHolder='First Name'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1) '
																							: errors?.child
																							? errors?.child[index]?.firstname
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1) '
																							: '1.33px solid rgba(203, 203, 203, 1) ',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}
																			/>
																			<br />
																			{Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.child ? (
																				errors?.child[index]?.firstname ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.child[index]?.firstname}
																					</span>
																				) : null
																			) : (
																				''
																			)}
																		</Grid>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`child.${index}.lastname`}
																				type='text'
																				placeHolder='Last Name'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1) '
																							: errors?.child
																							? errors?.child[index]?.lastname
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1) '
																							: '1.33px solid rgba(203, 203, 203, 1) ',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}
																			/>
																			<br />
																			{Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.child ? (
																				errors?.child[index]?.lastname ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.child[index]?.lastname}
																					</span>
																				) : null
																			) : (
																				''
																			)}
																		</Grid>
																		{depart.country_code === 'IN' &&
																		arrival.country_code === 'IN' ? (
																			''
																		) : (
																			<>
																				<Grid item xs={12} lg={6}>
																					<Field
																						name={`child.${index}.passportnumber`}
																						type='text'
																						placeHolder='Passport Number'
																						style={{
																							padding: 10,
																							width: '100%',
																							borderRadius: 5,
																							border:
																								Object?.keys(errors)?.length ===
																									0 &&
																								errors?.constructor === Object
																									? '1.33px solid rgba(203, 203, 203, 1) '
																									: errors?.child
																									? errors?.child[index]
																											?.passportnumber
																										? '2px solid red'
																										: '1.33px solid rgba(203, 203, 203, 1) '
																									: '1.33px solid rgba(203, 203, 203, 1) ',
																						}}
																						inputProps={{
																							style: { padding: 12 },
																						}}
																					/>
																					<br />
																					{Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object ? (
																						''
																					) : errors?.child ? (
																						errors?.child[index]
																							?.passportnumber ? (
																							<span
																								style={{
																									color: 'red',
																									fontSize: 12,
																								}}>
																								{
																									errors?.child[index]
																										?.passportnumber
																								}
																							</span>
																						) : null
																					) : (
																						''
																					)}
																				</Grid>
																				<Grid
																					item
																					xs={12}
																					lg={6}
																					className='expiry'>
																					<div
																						style={{
																							marginBottom: 5,
																							fontSize: 14,
																						}}>
																						Select Passport Expiry
																					</div>

																					<DatePicker
																						name={`child.${index}.expiry`}
																						defaultValue={
																							formik?.values.child[index].expiry
																								? dayjs(
																										formik?.values.child[index]
																											.expiry,
																										dateFormat
																								  )
																								: ''
																						}
																						style={{
																							padding: 10,
																							width: '100%',
																							borderRadius: 5,
																							border:
																								Object?.keys(errors)?.length ===
																									0 &&
																								errors?.constructor === Object
																									? '1.33px solid rgba(203, 203, 203, 1) '
																									: errors?.child
																									? errors?.child[index]?.expiry
																										? '2px solid red'
																										: '1.33px solid rgba(203, 203, 203, 1) '
																									: '',
																						}}
																						onChange={(date, dateString) => {
																							setFieldValue(
																								`child.${index}.expiry`,
																								dateString
																							);
																						}}
																					/>
																					<br />
																					{Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object ? (
																						''
																					) : errors?.child ? (
																						errors?.child[index]?.expiry ? (
																							<span
																								style={{
																									color: 'red',
																									fontSize: 12,
																								}}>
																								{errors?.child[index]?.expiry}
																							</span>
																						) : null
																					) : (
																						''
																					)}
																				</Grid>
																			</>
																		)}

																		<Grid item xs={12} lg={6} className='birth'>
																			<div
																				style={{
																					marginBottom: 5,
																					fontSize: 14,
																				}}>
																				Select Date of Birth
																			</div>

																			<DatePicker
																				name={`child.${index}.dob`}
																				defaultValue={
																					formik?.values.child[index].dob
																						? dayjs(
																								formik?.values.child[index].dob,
																								dateFormat
																						  )
																						: ''
																				}
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1) '
																							: errors?.child
																							? errors?.child[index]?.dob
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1) '
																							: '1.33px solid rgba(203, 203, 203, 1) ',
																				}}
																				onChange={(date, dateString) => {
																					setFieldValue(
																						`child.${index}.dob`,
																						dateString
																					);
																				}}
																			/>
																			<br />
																			{Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.child ? (
																				errors?.child[index]?.dob ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.child[index]?.dob}
																					</span>
																				) : null
																			) : (
																				''
																			)}
																		</Grid>
																	</Grid>
																</>
															) : (
																''
															)}
														</Box>
													</>
												))}
											</div>
										);
									}}
								/>
								<Divider />
								<FieldArray
									name='infant'
									render={(arrayHelpers) => {
										return (
											<div>
												{formik?.values?.infant?.map((infants, index) => (
													<>
														<Box
															style={{
																marginTop: 20,
																border: '1.33px solid rgba(203, 203, 203, 1)',
																padding: 20,
																borderRadius: 5,
															}}>
															<Box
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																}}>
																<Box style={{ display: 'flex' }}>
																	{Object?.keys(errors)?.length === 0 &&
																	errors?.constructor === Object ? (
																		<svg
																			focusable='false'
																			color='inherit'
																			fill='none'
																			aria-hidden='true'
																			role='presentation'
																			viewBox='0 0 20 21'
																			preserveAspectRatio='xMidYMid meet'
																			width='24px'
																			height='24px'
																			class='sc-hMqMXs llihTZ'>
																			<path
																				d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																				fill='rgb(253 121 14) '></path>
																		</svg>
																	) : errors?.infant ? (
																		errors?.infant?.length > 0 ? (
																			errors?.infant[index] === undefined ? (
																				<svg
																					focusable='false'
																					color='inherit'
																					fill='none'
																					aria-hidden='true'
																					role='presentation'
																					viewBox='0 0 20 21'
																					preserveAspectRatio='xMidYMid meet'
																					width='24px'
																					height='24px'
																					class='sc-hMqMXs llihTZ'>
																					<path
																						d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																						fill='rgb(253 121 14) '></path>
																				</svg>
																			) : (
																				<svg
																					focusable='false'
																					color='grey'
																					fill='rgb(216, 220, 222)'
																					aria-hidden='true'
																					role='presentation'
																					viewBox='0 0 20 21'
																					preserveAspectRatio='xMidYMid meet'
																					width='24px'
																					height='24px'
																					class='sc-hMqMXs llihTZ'>
																					<path
																						d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																						fill='rgb(216, 220, 222)'></path>
																				</svg>
																			)
																		) : (
																			<svg
																				focusable='false'
																				color='inherit'
																				fill='none'
																				aria-hidden='true'
																				role='presentation'
																				viewBox='0 0 20 21'
																				preserveAspectRatio='xMidYMid meet'
																				width='24px'
																				height='24px'
																				class='sc-hMqMXs llihTZ'>
																				<path
																					d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																					fill='rgb(253 121 14) '></path>
																			</svg>
																		)
																	) : (
																		<svg
																			focusable='false'
																			color='inherit'
																			fill='none'
																			aria-hidden='true'
																			role='presentation'
																			viewBox='0 0 20 21'
																			preserveAspectRatio='xMidYMid meet'
																			width='24px'
																			height='24px'
																			class='sc-hMqMXs llihTZ'>
																			<path
																				d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																				fill='rgb(253 121 14) '></path>
																		</svg>
																	)}

																	<div style={{ marginLeft: 5 }}>
																		{formik?.values?.infant[index].title &&
																		formik?.values?.infant[index].firstname &&
																		formik?.values?.infant[index].lastname
																			? `${formik?.values?.infant[index].title} ${formik?.values?.infant[index].firstname} ${formik?.values?.infant[index].lastname}`
																			: `Infant ${index + 1}`}
																	</div>
																</Box>
																<svg
																	style={{ cursor: 'pointer' }}
																	onClick={() =>
																		value3 === index
																			? setValue3(-1)
																			: setValue3(index)
																	}
																	focusable='false'
																	color='secondaryDark'
																	fill='currentcolor'
																	aria-hidden='true'
																	role='presentation'
																	viewBox='0 0 150 150'
																	preserveAspectRatio='xMidYMid meet'
																	size='9'
																	width='9'
																	height='9'
																	class='sc-hMqMXs gDtCsn'>
																	<path d='M86.8 118.6l60.1-61.5c5.5-5.7 5.5-14.8 0-20.5l-5-5.1c-5.4-5.5-14.3-5.6-19.8-.2l-.2.2L76 78.4 30.1 31.5c-5.4-5.5-14.3-5.6-19.8-.2l-.2.2-5 5.1c-5.5 5.7-5.5 14.8 0 20.5l60.1 61.5c2.8 2.9 6.8 4.4 10.8 4.1 4 .3 8-1.3 10.8-4.1z'></path>
																</svg>
															</Box>

															{index === value3 ? (
																<>
																	<Box display={'flex'} mt={2} ml={1.5} mb={2}>
																		<Box
																			onClick={() => {
																				setFieldValue(
																					`infant.${index}.gender`,
																					'Male'
																				);
																				console.log(
																					'formikvalues',
																					formik?.values.infant[index].gender
																				);
																			}}
																			style={{
																				padding: '10px 30px',
																				borderTopLeftRadius: 5,
																				borderBottomLeftRadius: 5,
																				background:
																					formik?.values.infant[index]
																						.gender === 'Male'
																						? 'rgb(8 128 130)'
																						: '',
																				color:
																					formik?.values.infant[index]
																						.gender === 'Male'
																						? 'white'
																						: '',
																				cursor: 'pointer',
																				border:
																					Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object
																						? '1.33px solid rgba(203, 203, 203, 1) '
																						: errors?.infant
																						? errors?.infant[index]?.gender
																							? '2px solid red'
																							: '1.33px solid rgba(203, 203, 203, 1) '
																						: '1.33px solid rgba(203, 203, 203, 1) ',
																			}}>
																			MALE
																		</Box>
																		<Box
																			onClick={() => {
																				setFieldValue(
																					`infant.${index}.gender`,
																					'Female'
																				);
																				console.log(
																					'formikvalues',
																					formik?.values.infant[index].gender
																				);
																			}}
																			style={{
																				// border:
																				// 	'1.33px solid rgba(203, 203, 203, 1)',
																				padding: '10px 30px',
																				borderTopRightRadius: 5,
																				borderBottomRightRadius: 5,
																				background:
																					formik?.values.infant[index]
																						.gender === 'Female'
																						? 'rgb(8 128 130)'
																						: '',
																				color:
																					formik?.values.infant[index]
																						.gender === 'Female'
																						? 'white'
																						: '',
																				cursor: 'pointer',
																				border:
																					Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object
																						? '1.33px solid rgba(203, 203, 203, 1) '
																						: errors?.infant
																						? errors?.infant[index]?.gender
																							? '2px solid red'
																							: '1.33px solid rgba(203, 203, 203, 1) '
																						: '1.33px solid rgba(203, 203, 203, 1) ',
																			}}>
																			FEMALE
																		</Box>
																	</Box>
																	<Grid
																		container
																		style={{ marginTop: 5, padding: 10 }}
																		spacing={4}>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`infant.${index}.title`}
																				as='select'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1) '
																							: errors?.infant
																							? errors?.infant[index]?.title
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1) '
																							: '1.33px solid rgba(203, 203, 203, 1) ',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}>
																				{title.map((item, index) => (
																					<option value={item}>{item}</option>
																				))}
																			</Field>
																			{Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.infant ? (
																				errors?.infant[index]?.title ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.infant[index]?.title}
																					</span>
																				) : null
																			) : (
																				''
																			)}
																		</Grid>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`infant.${index}.firstname`}
																				type='text'
																				placeHolder='First Name'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1) '
																							: errors?.infant
																							? errors?.infant[index]?.firstname
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1) '
																							: '1.33px solid rgba(203, 203, 203, 1) ',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}
																			/>
																			<br />
																			{Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.infant ? (
																				errors?.infant[index]?.firstname ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.infant[index]?.firstname}
																					</span>
																				) : null
																			) : (
																				''
																			)}
																		</Grid>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`infant.${index}.lastname`}
																				type='text'
																				placeHolder='Last Name'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1) '
																							: errors?.infant
																							? errors?.infant[index]?.lastname
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1) '
																							: '1.33px solid rgba(203, 203, 203, 1) ',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}
																			/>
																			<br />
																			{Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.infant ? (
																				errors?.infant[index]?.lastname ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.infant[index]?.lastname}
																					</span>
																				) : null
																			) : (
																				''
																			)}
																		</Grid>
																		{depart.country_code === 'IN' &&
																		arrival.country_code === 'IN' ? (
																			''
																		) : (
																			<>
																				<Grid item xs={12} lg={6}>
																					<Field
																						name={`infant.${index}.passportnumber`}
																						type='text'
																						placeHolder='Passport Number'
																						style={{
																							padding: 10,
																							width: '100%',
																							borderRadius: 5,
																							border:
																								Object?.keys(errors)?.length ===
																									0 &&
																								errors?.constructor === Object
																									? '1.33px solid rgba(203, 203, 203, 1) '
																									: errors?.infant
																									? errors?.infant[index]
																											?.passportnumber
																										? '2px solid red'
																										: '1.33px solid rgba(203, 203, 203, 1) '
																									: '1.33px solid rgba(203, 203, 203, 1) ',
																						}}
																						inputProps={{
																							style: { padding: 12 },
																						}}
																					/>
																					<br />
																					{Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object ? (
																						''
																					) : errors?.infant ? (
																						errors?.infant[index]
																							?.passportnumber ? (
																							<span
																								style={{
																									color: 'red',
																									fontSize: 12,
																								}}>
																								{
																									errors?.infant[index]
																										?.passportnumber
																								}
																							</span>
																						) : null
																					) : (
																						''
																					)}
																				</Grid>
																				<Grid
																					item
																					xs={12}
																					lg={6}
																					className='expiry'>
																					<div
																						style={{
																							marginBottom: 5,
																							fontSize: 14,
																						}}>
																						Select Passport Expiry
																					</div>

																					<DatePicker
																						name={`infant.${index}.expiry`}
																						defaultValue={
																							formik?.values.infant[index]
																								.expiry
																								? dayjs(
																										formik?.values.infant[index]
																											.expiry,
																										dateFormat
																								  )
																								: ''
																						}
																						style={{
																							padding: 10,
																							width: '100%',
																							borderRadius: 5,
																							border:
																								Object?.keys(errors)?.length ===
																									0 &&
																								errors?.constructor === Object
																									? '1.33px solid rgba(203, 203, 203, 1) '
																									: errors?.infant
																									? errors?.infant[index]
																											?.expiry
																										? '2px solid red'
																										: '1.33px solid rgba(203, 203, 203, 1) '
																									: '1.33px solid rgba(203, 203, 203, 1) ',
																						}}
																						onChange={(date, dateString) => {
																							setFieldValue(
																								`infant.${index}.expiry`,
																								dateString
																							);
																						}}
																					/>
																					<br />
																					{Object?.keys(errors)?.length === 0 &&
																					errors?.constructor === Object ? (
																						''
																					) : errors?.infant ? (
																						errors?.infant[index]?.expiry ? (
																							<span
																								style={{
																									color: 'red',
																									fontSize: 12,
																								}}>
																								{errors?.infant[index]?.expiry}
																							</span>
																						) : null
																					) : (
																						''
																					)}
																				</Grid>
																			</>
																		)}

																		<Grid item xs={12} lg={6} className='birth'>
																			<div
																				style={{
																					marginBottom: 5,
																					fontSize: 14,
																				}}>
																				Select Date of Birth
																			</div>

																			<DatePicker
																				name={`infant.${index}.dob`}
																				defaultValue={
																					formik?.values.infant[index].dob
																						? dayjs(
																								formik?.values.infant[index]
																									.dob,
																								dateFormat
																						  )
																						: ''
																				}
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1) '
																							: errors?.infant
																							? errors?.infant[index]?.dob
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1) '
																							: '1.33px solid rgba(203, 203, 203, 1) ',
																				}}
																				onChange={(date, dateString) => {
																					setFieldValue(
																						`infant.${index}.dob`,
																						dateString
																					);
																				}}
																			/>
																			<br />
																			{Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.infant ? (
																				errors?.infant[index]?.dob ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.infant[index]?.dob}
																					</span>
																				) : null
																			) : (
																				''
																			)}
																		</Grid>
																	</Grid>
																</>
															) : (
																''
															)}
														</Box>
													</>
												))}
											</div>
										);
									}}
								/>
							</Box>
							<Grid container>
								<Grid item xs={3}></Grid>
								<Grid item xs={12} lg={6}>
									<Button
										type='submit'
										style={{
											background: 'rgb(253 121 14) ',
											color: 'white',
											width: '100%',
											marginTop: 30,
											padding: 10,
											borderRadius: 12,
										}}>
										Continue
									</Button>
								</Grid>
								<Grid item xs={3}></Grid>
							</Grid>
						</Form>
					);
				}}
			</Formik>
			<FirstNameFormatModal
				open={open}
				handleOpen={handleOpen}
				handleClose={handleClose}
			/>
			<LastNameFormatModal
				open={open2}
				handleOpen={handleOpen2}
				handleClose={handleClose2}
			/>
		</>
	);
};

export default FlightSummaryForm;
