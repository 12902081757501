import React, { useEffect } from 'react';
import {
	Grid,
	Container,
	Box,
	Button,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import locat from '../../assets/images/locat.svg';
import star from '../../assets/images/star.svg';
import { Divider } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showtourpackageDispatch } from '../../reducers/HomeReducer';
import Card from './Card';
const Package = () => {
	const [data, setData] = useState();
	const { tourpackage } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const handleChange = (val) => {
		setData(val);
	};
	useEffect(() => {
		dispatch(showtourpackageDispatch());
	}, []);
	let filterCategory = [];
	let filter = [];
	if (tourpackage?.result?.length > 0) {
		filterCategory = [
			...new Set(
				tourpackage &&
					tourpackage?.result &&
					tourpackage?.result?.length > 0 &&
					tourpackage?.result?.map((item) => item.destination)
			),
		];
	}
	useEffect(() => {
		if (filterCategory.length > 0) {
			setData(filterCategory[0]);
		}
	}, [tourpackage]);
	if (tourpackage?.result?.length > 0) {
		tourpackage &&
			tourpackage?.result &&
			tourpackage?.result?.length > 0 &&
			tourpackage?.result?.map((i) => {
				if (i.destination === data) {
					filter.push({
						...i,
					});
				}
			});
	}
	// console.log('filter', filter);
	return (
		<>
			<div
				style={{
					marginTop: 100,
					backgroundImage: `url(${'https://marinersforex.com/photo/bac.svg'}`,
				}}>
				{' '}
				<p
					style={{
						textAlign: 'center',
						color: 'rgb(253 121 14) ',
						fontWeight: 'bold',
					}}>
					Explore the world
				</p>
				<Container style={{ marginTop: 20 }}>
					<h3 className='title'>Top Amazing Package</h3>

					{/* Buttons */}
					<div className='button-group'>
						<button className='btn btn-domestic'>Domestic</button>
						<button className='btn btn-international'>International</button>
					</div>
				</Container>
				<Container>
					<Grid container spacing={2}>
						{Array.from(Array(10)).map((i) => {
							return (
								<Grid item xs={4}>
									<Card />
								</Grid>
							);
						})}
					</Grid>
				</Container>
			</div>
		</>
	);
};

export default Package;
