import React, { useEffect } from 'react';
import loc from '../../assets/images/locat.svg';
import calender from '../../assets/images/calender.svg';
import bus from '../../assets/images/bus.avif';
import dayjs from 'dayjs';
import search from '../../assets/images/search.svg';
import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, TextField, Box, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { showBannerDispatch } from '../../reducers/HomeReducer';
import { setSelectedBus, setSelectedDesBus } from '../../reducers/UiReducer';
import { fetchBusCityDispatch } from '../../reducers/BusReducer';
const dateFormat = 'DD-MM-YYYY';

const BusSlider = () => {
	const { banner } = useSelector((state) => state.home);
	const { busCity } = useSelector((state) => state.bus);
	const { selectedBus, selectedDesBus } = useSelector((state) => state.ui);
	const date = new Date();
	const [num, setNum] = useState(1);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [filterData, setFilterData] = useState('');
	const [filterData2, setFilterData2] = useState('');
	const [departDate, setDepartDate] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const open = Boolean(anchorEl);
	let dateFor = date.toLocaleString().split(',')[0].split('/');
	const newDate = `${dateFor[1].length > 1 ? '' : 0}${dateFor[1]}-${
		dateFor[0].length > 1 ? '' : 0
	}${dateFor[0]}-${dateFor[2]}`;
	console.log('date', newDate);
	useEffect(() => {
		let form = {};
		dispatch(showBannerDispatch());
		dispatch(fetchBusCityDispatch(form));
	}, []);
	let filter = [];
	if (banner?.result?.length > 0) {
		banner?.result?.map((i) => {
			if (i.inventory === 'Bus') {
				filter.push({
					...i,
				});
			}
		});
	}
	const handlePrev = () => {
		if (num > 1) {
			setNum(num - 1);
		}
	};
	const handleNext = () => {
		if (num > 0 && num < filter.length) {
			setNum(num + 1);
		}
	};
	const handleChange = (event) => {
		let value = event ? event.target.value.toLowerCase() : 'del';
		let results = [];
		results =
			busCity &&
			busCity?.result &&
			busCity?.result?.cities?.filter((item) => {
				return (
					item.name.toLowerCase().includes(value.toLowerCase()) ||
					item.state.toLowerCase().includes(value.toLowerCase())
				);
			});
		console.log('searchresult', results);
		if (event) {
		} else {
			dispatch(setSelectedBus(results ? results[0] : null));
		}
		if (results?.length <= 80) {
			setFilterData(results);
		}
	};
	const handleChange2 = (event) => {
		let value = event ? event.target.value.toLowerCase() : 'kol';
		let results = [];
		results =
			busCity &&
			busCity?.result &&
			busCity?.result?.cities?.filter((item) => {
				return (
					item.name.toLowerCase().includes(value.toLowerCase()) ||
					item.state.toLowerCase().includes(value.toLowerCase())
				);
			});
		console.log('searchresult', results);
		if (event) {
		} else {
			dispatch(setSelectedDesBus(results ? results[0] : null));
		}
		if (results?.length <= 80) {
			setFilterData2(results);
		}
	};

	function disabledDate(current) {
		return moment().add(-1, 'days') >= current;
	}
	const onChange = (date, dateString) => {
		setDepartDate(dateString);
	};
	const handleTBO = () => {
		let formData = {
			departure_city: selectedBus.name.split(' ')[0],
			arrival_city: selectedDesBus.name.split(' ')[0],
			sourceid: selectedBus.id,
			destinationid: selectedDesBus.id,
			dateofjourney: departDate,
		};
		navigate('/busresult', { state: formData });
	};

	return (
		<>
			<div className='slider' style={{ marginTop: matches ? 5 : 50 }}>
				<div className='list'>
					{filter?.map((i, index) => {
						return (
							<div className={`item ${num === index + 1 ? 'active' : ''}`}>
								<img src={i.imgpath} />
								<div className='content'>
									<p
										style={{
											fontFamily: 'Yesteryear',
											color: 'rgb(253 121 14) ',
											fontSize: 28,
										}}>
										{i.header}
									</p>
									<h2
										style={{
											lineHeight: matches ? '55px' : '80px',
											fontSize: matches ? 50 : 60,
											textTransform: 'uppercase',

											color: 'rgba(255, 255, 255, 1)',
											fontWeight: 700,
										}}>
										{i.title}
									</h2>
									{/* <p
										style={{
											color: 'rgba(255, 255, 255, 1)',
											fontSize: matches ? 10 : 16,

											marginTop: 10,
										}}>
										{i.description}
									</p>
									<button
										style={{
											backgroundColor: 'rgb(253 121 14) ',
											color: 'white',
											padding: '15px 30px',
											marginTop: 25,
											borderRadius: 5,
											textTransform: 'uppercase',
										}}>
										LEt,s get started
									</button> */}
								</div>

								<div
									className='transbox'
									style={{
										backgroundColor: 'rgba(255, 255, 255, 0.12)',
										borderRadius: 5,
									}}>
									<Grid container spacing={3}>
										<Grid item xs={12} lg={4}>
											<Box display={'flex'}>
												<img
													src={loc}
													style={{
														height: 25,
														width: 25,
														objectFit: 'contain',
													}}
												/>
												<Box style={{ width: '100%' }}>
													<span
														style={{
															color: 'white',
															marginLeft: 10,
															fontSize: 14,
														}}>
														From
													</span>
													<Autocomplete
														required
														id='country-select-demo'
														value={selectedBus}
														sx={{
															width: '100%',
															marginLeft: 2,
															marginTop: 1,
															'& .MuiFormLabel-root': {
																color: 'white',
															},
															'& .MuiInputBase-input': {
																color: 'rgba(207, 201, 201, 1)',
															},
															'& .MuiSvgIcon-root': {
																color: 'white',
															},
														}}
														options={
															filterData && filterData.length > 0
																? filterData
																: []
														}
														autoHighlight
														ListboxProps={{
															className: 'myCustomList',
														}}
														getOptionLabel={(option) =>
															`${option.name} (${option.state})`
														}
														renderOption={(props, option) => (
															<Box
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	alignItems: 'center',
																}}
																component='li'
																sx={{
																	'& > img': {
																		mr: 2,
																		flexShrink: 0,
																		fontSize: 14,
																	},
																}}
																{...props}>
																<Box>
																	<Box display={'flex'}>
																		<img
																			src={bus}
																			style={{ width: 20, height: 20 }}
																		/>
																		<p style={{ fontSize: 13, marginLeft: 5 }}>
																			{option.name} - {option.state}
																		</p>
																	</Box>
																	<p
																		style={{
																			fontSize: 13,
																			marginLeft: 25,

																			textOverflow: 'ellipsis',
																			overflow: 'hidden',
																			width: '200px',
																			whiteSpace: 'nowrap',
																		}}>
																		{option.state}
																	</p>
																</Box>
															</Box>
														)}
														onChange={(event, value) => {
															dispatch(setSelectedBus(value));
														}}
														renderInput={(params) => (
															<TextField
																{...params}
																id='standard-basic'
																variant='standard'
																onChange={handleChange}
																style={{ fontSize: 12 }}
																inputProps={{
																	...params.inputProps,
																	autoComplete: 'new-password', // disable autocomplete and autofill
																}}
															/>
														)}
													/>
												</Box>
											</Box>
										</Grid>
										<Grid item xs={12} lg={4}>
											<Box display={'flex'}>
												<img
													src={loc}
													style={{
														height: 25,
														width: 25,
														objectFit: 'contain',
													}}
												/>
												<Box style={{ width: '100%' }}>
													<span
														style={{
															color: 'white',
															marginLeft: 10,
															fontSize: 14,
														}}>
														To
													</span>
													<Autocomplete
														required
														id='country-select-demo'
														value={selectedDesBus}
														sx={{
															width: '100%',
															marginLeft: 2,
															marginTop: 1,
															'& .MuiFormLabel-root': {
																color: 'white',
															},
															'& .MuiInputBase-input': {
																color: 'rgba(207, 201, 201, 1)',
															},
															'& .MuiSvgIcon-root': {
																color: 'white',
															},
														}}
														options={
															filterData2 && filterData2.length > 0
																? filterData2
																: []
														}
														autoHighlight
														ListboxProps={{
															className: 'myCustomList',
														}}
														getOptionLabel={(option) =>
															`${option.name} (${option.state})`
														}
														renderOption={(props, option) => (
															<Box
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	alignItems: 'center',
																}}
																component='li'
																sx={{
																	'& > img': {
																		mr: 2,
																		flexShrink: 0,
																		fontSize: 14,
																	},
																}}
																{...props}>
																<Box>
																	<Box display={'flex'}>
																		<img
																			src={bus}
																			style={{ width: 20, height: 20 }}
																		/>
																		<p style={{ fontSize: 13, marginLeft: 5 }}>
																			{option.name} - {option.state}
																		</p>
																	</Box>
																	<p
																		style={{
																			fontSize: 13,
																			marginLeft: 25,

																			textOverflow: 'ellipsis',
																			overflow: 'hidden',
																			width: '200px',
																			whiteSpace: 'nowrap',
																		}}>
																		{option.state}
																	</p>
																</Box>
															</Box>
														)}
														onChange={(event, value) => {
															dispatch(setSelectedDesBus(value));
														}}
														renderInput={(params) => (
															<TextField
																{...params}
																id='standard-basic'
																variant='standard'
																onChange={handleChange2}
																style={{ fontSize: 12 }}
																inputProps={{
																	...params.inputProps,
																	autoComplete: 'new-password', // disable autocomplete and autofill
																}}
															/>
														)}
													/>
												</Box>
											</Box>
										</Grid>
										<Grid item xs={12} lg={2.5}>
											<Box display={'flex'} style={{ width: '100%' }}>
												<img
													src={calender}
													style={{
														height: 25,
														width: 25,
														objectFit: 'contain',
														marginTop: 5,
													}}
												/>
												<Box>
													<span
														style={{
															color: 'white',
															marginLeft: 10,
															fontSize: 14,
														}}>
														Departure
													</span>
													<DatePicker
														disabledDate={disabledDate}
														onChange={onChange}
														placeholder=''
														defaultValue={dayjs(newDate, dateFormat)}
														style={{
															width: '100%',
															padding: 8,
															border: 'none',
															borderBottom: '1px solid',
															borderRadius: '1px',
															boxShadow: 'none',
															background: 'transparent',
														}}
													/>
												</Box>
											</Box>
										</Grid>
										<Grid item xs={12} lg={1}>
											<Box
												onClick={handleTBO}
												style={{
													backgroundColor: 'rgb(253 121 14) ',
													textAlign: 'center',
													padding: matches ? '20px' : '30px',
													borderRadius: '5px',
													cursor: 'pointer',
												}}
												textAlign={'center'}>
												<img
													src={search}
													style={{
														height: 25,
														width: 25,
														display: 'inline',
														objectFit: 'contain',
													}}
												/>
											</Box>
										</Grid>
									</Grid>
								</div>
							</div>
						);
					})}
				</div>
				<div className='arrows'>
					<button id='prev' onClick={handlePrev}>
						{'<'}
					</button>
					<button id='next' style={{ marginTop: 20 }} onClick={handleNext}>
						{'>'}
					</button>
				</div>
			</div>
		</>
	);
};

export default BusSlider;
